import React from "react";

export interface IConfigContext {
    configLoaded: boolean
    configBody?: IConfigBody
}

export interface IConfigBody {
    ad: any
    google: IGoogleConfig
    api: APIConfig
    logging: ILoggingConfig
    environment: IEnvironmentConfig
}

export interface IGoogleConfig {
    sitekey: string
}

export interface APIConfig {
    baseUrl: string
    publicBaseUrl: string
    apiKey: string
    endpoints: APIEndpointConfig
}

export interface APIEndpointConfig {
    company: ICompanyConfig
    attributes: IAttributesConfig
    activities: IActivitiesConfig
    measurements: IMeasurementsConfig
    functions: IFunctionsConfig
    connectors: IConnectorsConfig
    shop: IShopConfig
    sources: ISourcesConfig
    vacancies: IVacanciesConfig
    general: IGeneralConfig
    admin: IAdminConfig
    search: ISearchConfig,
    transfer: ITransferConfig
    scopes: string[]
}

export interface ICompanyConfig {
    detail: string
    logo: string
    completion: string
}

export interface IAttributesConfig {
    list: string
}

export interface ITransferConfig {
    detail: string
}

export interface IActivitiesConfig {
    list: string
    detail: string
}

export interface IMeasurementsConfig {
    list: string
    detail: string
    answers: string
    results: string
}

export interface IFunctionsConfig {
    list: string
    detail: string
    feedbacksValidate: string
    feedbacksList: string
    feedbacksDetail: string
}

export interface IConnectorsConfig {
    list: string
    setup: string
    apiKey: string
}

export interface IShopConfig {
    list: string
    detail: string
    buy: string    
}

export interface ISourcesConfig {
    list: string
}

export interface IVacanciesConfig {
    list: string
    detail: string
}

export interface IGeneralConfig {
    translations: string
    settings: string
    contact: string
}

export interface IAdminConfig {
    companies: ICompanyAdminConfig
    functions: IFunctionAdminConfig
}

export interface ICompanyAdminConfig {
    list: string
    detail: string
}

export interface IFunctionAdminConfig {
    list: string
}

export interface ISearchConfig {
    professions: string
    skills: string
    companies: string
    fieldOfWork: string
}

export interface ILoggingConfig {
    aiConnectionString: string
}

export interface IEnvironmentConfig {
    shortName: string
}

export const ConfigContext = React.createContext<IConfigContext>({ configLoaded: false });