import PageHeader from '@/components/page-header/page-header';
import { ContactForm } from '@/general/model/contact-form';
import { t } from 'i18next';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import './pages.scss';
import { ConfigContext } from '@/context/config-context';
import PageFooter from '@/components/footers/page-footer';
import toast from 'react-hot-toast';
import {Link} from "react-router-dom";
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

function ContactPage() {
    
    useDocumentTitle(t('documentTitles:contact'));

    const configContext = useContext(ConfigContext);
    const [validated, setValidated] = useState<boolean>(false);
    const [recaptchaCompleted, setRecaptchaCompleted] = useState<boolean>(false);
    const [contactForm, setContactForm] = useState<ContactForm>({ firstName: '', lastName: '', email: '', phone: '', subject: t('public:contact:subjectOptions.option1'), message: '', reCaptchaResponse: '' });

    const formRef = createRef<HTMLFormElement>();
    const recaptchaRef = createRef<ReCAPTCHA>();

    useEffect(() => {
        clearForm();
    }, []);

    const clearForm = () => {
        setValidated(false);
        setContactForm({ firstName: '', lastName: '', email: '', phone: '', subject: t('public:contact:subjectOptions.option1'), message: '', reCaptchaResponse: '' });
    }

    useEffect(() => {

        if (!contactForm.reCaptchaResponse) {
            return;
        }

        fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.general.contact}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(contactForm)
        })
            .then((response) => {
                if (response.ok) {
                    toast.success(t('public:contact:toasts:contactSuccess'));
                    clearForm();
                }
            })
            .catch(
                (error) => {
                    toast.error(t('public:contact:toasts:contactFailed'));
                });
    }, [contactForm]);

    const handleFormSubmit = (e) => {

        e.preventDefault();

        setValidated(true);
        if (formRef.current?.checkValidity() && recaptchaCompleted) {

            const recaptchaValue = recaptchaRef.current?.getValue();
            setContactForm({ ...contactForm, reCaptchaResponse: recaptchaValue });
        }
    }

    return (
        <div className="app-container">
            <div className="main-container">
                <main className="public contact">

                    <PageHeader
                        title={t('documentTitles:contact')}
                        description={
                            <>
                                {t('public:contact:description:part1')} <Link className="link" to="/faq">{t('public:contact:description:faqLink')}</Link>. {t('public:contact:description:part2')}
                            </>
                        }
                    />

                    <Container fluid className='bg-lichtblauw'>
                        <Container>
                            <Row className="py-5">

                                <Col sm={12} md={12} lg={6} xl={6} className="px-0 pe-3 position-relative">
                                    <h2 className='font-heavy'>{t('public:contact:contactFormTitle')}</h2>
                                    <Form ref={formRef} validated={validated} noValidate onSubmit={(e) => handleFormSubmit(e)}>

                                        <Form.Group as={Row} className="mt-4">
                                            <Col>
                                                <Form.Label>{t('public:contact:labelFirstName')}<span className='required'>({t('general:required')})</span></Form.Label>
                                                <Form.Control type="text" placeholder={t('public:contact:placeholderFirstName')} required value={contactForm.firstName ?? ''}
                                                    onChange={(e) => setContactForm({ ...contactForm, firstName: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>{t('public:contact:labelLastName')}<span className='required'>({t('general:required')})</span></Form.Label>
                                                <Form.Control type="text" placeholder={t('public:contact:placeholderLastName')} required value={contactForm.lastName ?? ''}
                                                    onChange={(e) => setContactForm({ ...contactForm, lastName: e.target.value })} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mt-4">
                                            <Col>
                                                <Form.Label>{t('public:contact:labelEmail')}<span className='required'>({t('general:required')})</span></Form.Label>
                                                <Form.Control type="email" placeholder={t('public:contact:placeholderEmail')} required value={contactForm.email ?? ''}
                                                    onChange={(e) => setContactForm({ ...contactForm, email: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>{t('public:contact:labelPhone')}<span className='required'>({t('general:required')})</span></Form.Label>
                                                <Form.Control type="tel" placeholder={t('public:contact:placeholderPhone')} required value={contactForm.phone ?? ''}
                                                    onChange={(e) => setContactForm({ ...contactForm, phone: e.target.value })} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mt-4">
                                            <Col>
                                                <Form.Label>{t('public:contact:labelSubject')}<span className='required'>({t('general:required')})</span></Form.Label>
                                                <select className='form-select' value={contactForm.subject} onChange={(e) => setContactForm({ ...contactForm, subject: e.target.value })}>
                                                    <option value={t('public:contact:subjectOptions.option1')}>{t('public:contact:subjectOptions.option1')}</option>
                                                    <option value={t('public:contact:subjectOptions.option2')}>{t('public:contact:subjectOptions.option2')}</option>
                                                    <option value={t('public:contact:subjectOptions.option3')}>{t('public:contact:subjectOptions.option3')}</option>
                                                    <option value={t('public:contact:subjectOptions.option4')}>{t('public:contact:subjectOptions.option4')}</option>
                                                    <option value={t('public:contact:subjectOptions.option5')}>{t('public:contact:subjectOptions.option5')}</option>
                                                </select>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mt-4">
                                            <Col>
                                                <Form.Label>{t('public:contact:labelMessage')}</Form.Label>
                                                <Form.Control as={'textarea'} rows={12} placeholder={t('public:contact:placeholderMessage')} value={contactForm.message ?? ''}
                                                    onChange={(e) => setContactForm({ ...contactForm, message: e.target.value })} />
                                            </Col>
                                        </Form.Group>

                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            className='mt-4'
                                            sitekey={configContext.configBody.google.sitekey}
                                            onChange={() => setRecaptchaCompleted(true)}
                                            onErrored={() => setRecaptchaCompleted(false)}
                                            onExpired={() => setRecaptchaCompleted(false)} />
                                        {validated && !recaptchaCompleted && <p className='text-danger'>{t('public:contact:recaptchaRequired')}</p>}

                                        <button className='btn btn-secondary mt-4' type="submit">{t('public:contact:submitBtn')}</button>

                                    </Form>
                                </Col>

                                <Col sm={12} md={12} lg={6} xl={6} className="px-0 px-lg-5 pt-5 pt-lg-0">
                                    <h2 className='font-heavy'>{t('public:contact:contactDetailsTitle')}</h2>
                                    <address className='address my-4'>
                                        Koekoekweg 3
                                        <br />
                                        Postbus 90
                                        <br />
                                        8084 ZH ’t Harde
                                    </address>
                                    <address className='address my-4'>
                                        Evert van de Beekstraat 354
                                        <br />
                                        1118 CZ Schiphol
                                    </address>
                                    <div className='d-flex flex-column text-decoration-underline'>
                                        <a href="mailto:info@werkinhetvooruitzicht.nl">info@werkinhetvooruitzicht.nl</a>
                                        <a href="tel:+31885253505">+31 88 525 3505</a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                </main>

                <PageFooter />
            </div>
        </div>
    )
}

export default ContactPage