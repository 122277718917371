import { Route, Routes } from 'react-router-dom';
import BuyConnectorsOverview from './buy-connectors-overview';

function ShopModule() {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<BuyConnectorsOverview />} />                            
            </Route>
        </Routes>
    )
}

export default ShopModule;