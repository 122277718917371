import NavigationBlocker from "@/components/navigation-blocker/navigation-blocker";
import ProfessionMultiSearchBar from "@/components/profession-search/profession-multi-search-bar";
import { ProfessionSearchDocument } from "@/components/profession-search/profession-search-document";
import ProfessionSingleSearchBar from "@/components/profession-search/profession-single-search-bar";
import VacancyUpload from "@/components/vacancy-upload/vacancy-upload";
import { ConfigContext } from "@/context/config-context";
import { FunctionContext } from "@/context/function-context";
import useAuthenticatedDelete from "@/hooks/useAuthenticatedDelete";
import useDataSources from "@/hooks/useDataSources";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import Header from "@/layout/header/header";
import dayjs from 'dayjs';
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Container, Form, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { EmployerFunction } from "../model/employer-function";
import { FunctionDataSource } from "../model/function-data-source";
import { FunctionProfession } from "../model/function-profession";
import FunctionPositions from "./function-additional-info";
import FunctionCertifications from "./function-certifications";
import './function-edit.scss';
import FunctionLanguages from "./function-languages";
import FunctionPrimaryEmploymentConditions from "./function-primary-employment-conditions";
import FunctionRequirements from "./function-requirements";
import FunctionSecondaryEmploymentConditions from "./function-secondary-employment-conditions";
import FunctionSkills from "./function-skills";
import FunctionValidators from "./function-validators";

function FunctionEdit() {

    useDocumentTitle(t('documentTitles:functionEdit'));

    const { id } = useParams();
    const functionContext = useContext(FunctionContext);
    const configContext = useContext(ConfigContext);
    const navigate = useNavigate();
    const deleteRequest = useAuthenticatedDelete();
    const { sources } = useDataSources(functionContext.tempChanges?.professionIds);
    const [vacancyDataSource, setVacancyDataSource] = useState<FunctionDataSource>(undefined);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { mutate: mutateList } = useSWR(configContext.configBody.api.endpoints.functions.list);

    const { data: employerFunction, mutate: mutateFunction } = useSWR(id ? configContext.configBody.api.endpoints.functions.detail.replace('{id}', id) : null);

    useEffect(() => {

        if (employerFunction) {
            functionContext.updateEmployerFunction(employerFunction);
        }

    }, [id, employerFunction]);

    const handleProfessionChange = (profession: FunctionProfession) => {

        if (!profession) {
            const updatedFunction: EmployerFunction = {
                ...functionContext.tempChanges,
                name: '',
                customName: false,
                professionIds: []
            };

            functionContext.updateChanges(updatedFunction);
            return;
        }

        const updatedFunction: EmployerFunction = {
            ...functionContext.tempChanges,
            name: profession.name,
            customName: profession.professionId ? false : true,
            professionIds: profession.professionId ? [profession.professionId] : []
        };

        functionContext.updateChanges(updatedFunction);
    }

    const handleProfessionsChange = (professions: ProfessionSearchDocument[]) => {
        updateTempChanges('professionIds', professions?.map((p) => p.id));
    }

    const handleSaveFunction = () => {
        functionContext.saveEmployerFunction(functionContext.tempChanges).then(() => {
            mutateFunction();
        }).catch((error) => { });
    }

    const updateTempChanges = (propertyName, newValue) => {
        const updatedFunction: EmployerFunction = {
            ...functionContext.tempChanges,
            [propertyName]: newValue
        };
        functionContext.updateChanges(updatedFunction);
    };

    const handleEnableUpdateFunction = (enabled: boolean) => {
        let hasError = false;

        if (enabled) {
            // Check if employerFunction has at least 3 skills
            if ((functionContext.employerFunction?.skills?.essential?.length + functionContext.employerFunction?.skills?.optional?.length) < 3) {
                toast.error(t('functions:toasts:minimumSkills'));
                hasError = true;
            }

            // Check if employerFunction has at least 3 knowledge items
            if ((functionContext.employerFunction?.knowledge?.essential?.length + functionContext.employerFunction?.knowledge?.optional?.length) < 3) {
                toast.error(t('functions:toasts:minimumKnowledge'));
                hasError = true;
            }

            // Check if education is set
            if (!functionContext.employerFunction?.requiredEducationLevel) {
                toast.error(t('functions:toasts:educationNotSet'));
                hasError = true;
            }
        }

        if (hasError) {
            return;
        }

        const updatedFunction: EmployerFunction = {
            ...functionContext.employerFunction,
            enabled: enabled
        };

        functionContext.saveEmployerFunction(updatedFunction).then(() => {
            mutateFunction();
        }).catch((error) => { });
    }

    function handleDeleteFunction() {

        deleteRequest(configContext.configBody.api.endpoints.functions.detail.replace('{id}', functionContext.employerFunction?.id))
            .then((response) => {
                toast.success(t('functions:toasts:deleteSuccess'));
                setShowConfirmModal(false);
                mutateList();
                navigate(`/profile/functions`);
            })
            .catch((error) => {
                console.error(error);
                toast.error(`${t('functions:toasts:deleteFailed')} ${error.message}`);
            });
    }

    return (
        <div className="function-container">
            <Container fluid className="bg-oranje position-relative pb-5">
                <Container>

                    <div className='header-function-img'>
                        <img src='https://images.pexels.com/photos/3184360/pexels-photo-3184360.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' className='img-fluid d-none d-xl-block' alt='' />
                    </div>

                    <div className="position-relative pb-5">
                        <Header />
                    </div>

                    <Row className='position-relative'>
                        <Col sm={12} md={12} lg={12} xl={6} xxl={6} className='d-flex flex-column text-wit'>

                            {
                                functionContext.editable ?
                                    <>
                                        <ProfessionSingleSearchBar profession={{ name: functionContext.tempChanges?.name, professionId: functionContext.tempChanges?.professionIds ? functionContext.tempChanges?.professionIds[0] : null }} onProfessionChange={(s) => { handleProfessionChange(s) }} />

                                        {
                                            functionContext.tempChanges?.name && functionContext.tempChanges?.customName &&
                                            <ProfessionMultiSearchBar selectedProfessions={sources} onProfessionsChange={(s) => handleProfessionsChange(s)} />
                                        }
                                    </>
                                    :
                                    <Form.Group as={Row} className="mt-4">
                                        <Col>
                                            <h2 className='text-thin text-wit pt-2'>{functionContext.employerFunction?.name}</h2>
                                        </Col>
                                    </Form.Group>
                            }

                            <Form.Group as={Row} className="mt-4">
                                <Col>
                                    {functionContext.editable ?
                                        <>
                                            <Form.Label className={`${!functionContext.editable && 'no-edit'}`}>{t('functions:detail:labelDescription')}</Form.Label>
                                            <Form.Control as="textarea" rows={6} placeholder={t('functions:detail:placeholderDescription')} value={functionContext.tempChanges?.description ?? ''} onChange={e => updateTempChanges('description', e.target.value)} />
                                        </>
                                        : <div>{functionContext.employerFunction?.description}</div>
                                    }
                                </Col>
                            </Form.Group>

                            {
                                functionContext.editable &&
                                <VacancyUpload
                                    dataSourceRetrieved={(e) => { setVacancyDataSource(e); updateTempChanges('vacancyId', e?.id) }}
                                    vacancyDescriptionChanged={(s) => updateTempChanges('vacancyDescription', s)}
                                    employerfunctionVacancyDescription={functionContext.employerFunction?.vacancyDescription}
                                    employerfunctionVacancyDataSourceId={functionContext.employerFunction?.vacancyId} />
                            }

                            {
                                !functionContext.editable &&
                                <div className='metadata mt-4 d-flex d-md-block justify-content-center'>
                                    <span className="me-2">{t('functions:detail:labelUpdatedOn')}</span>
                                    <span>{
                                        (functionContext.employerFunction?.updatedOn && dayjs(functionContext.employerFunction?.updatedOn).isValid())
                                            ? dayjs(functionContext.employerFunction?.updatedOn).format("DD-MM-YYYY - HH:mm") : '-'}
                                    </span>
                                </div>
                            }

                            <div className='actions mt-4 d-flex d-md-block justify-content-center'>
                                <ButtonGroup>
                                    {
                                        !functionContext.editable &&
                                        <>
                                            <Button variant="zwartblauw" onClick={() => functionContext.updateEditable(true)}>
                                                <i className="fas fa-pen me-2"></i>
                                                <span>{t('functions:edit:editFunction')}</span>
                                            </Button>
                                            <Button variant="zwartblauw" onClick={() => setShowConfirmModal(true)}>
                                                <i className="fas fa-trash-can me-2"></i>
                                                <span>{t('functions:edit:deleteFunction')}</span>
                                            </Button>

                                            {functionContext.employerFunction?.enabled ?
                                                <Button variant="zwartblauw" onClick={() => handleEnableUpdateFunction(false)}>
                                                    <i className="fas fa-ban me-2"></i>
                                                    <span>{t('functions:edit:deactivateFunction')}</span>
                                                </Button>
                                                :
                                                <Button variant="zwartblauw" onClick={() => handleEnableUpdateFunction(true)}>
                                                    <i className="fas fa-check me-2"></i>
                                                    <span>{t('functions:edit:activateFunction')}</span>
                                                </Button>
                                            }
                                        </>
                                    }

                                    {
                                        functionContext.editable &&
                                        <>
                                            <Button variant="zwartblauw" onClick={() => functionContext.updateEditable(false)}>
                                                <i className="fas fa-xmark me-2"></i>
                                                <span>{t('general:cancel')}</span>
                                            </Button>

                                            <Button variant="zwartblauw" onClick={() => handleSaveFunction()}>
                                                <i className="fas fa-floppy-disk me-2"></i>
                                                <span>{t('general:save')}</span>
                                            </Button>
                                        </>
                                    }
                                </ButtonGroup>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="skills bg-blauw py-5">
                <Container>
                    <FunctionSkills sources={vacancyDataSource ? [...sources, vacancyDataSource] : sources} />
                    <FunctionValidators />
                </Container>
            </Container>

            <Container fluid className="bg-blauw pb-5">
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <FunctionPrimaryEmploymentConditions />
                            <FunctionRequirements />
                            <FunctionPositions />
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <FunctionSecondaryEmploymentConditions />
                            <FunctionLanguages />
                            <FunctionCertifications />
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Modal className="confirm-modal wihv-modal" show={showConfirmModal} onHide={() => setShowConfirmModal(false)} backdrop="static" centered>
                <Modal.Header>
                    <div className='h3 text-donkerblauw'>{t('functions:edit:deleteFunction')}</div>
                </Modal.Header>

                <Modal.Body as={Container}>
                    <p>{t('functions:edit:confirmDeleteFunction')}</p>
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-primary-outline transparent" onClick={() => setShowConfirmModal(false)}>
                        {t('general:no')}
                    </button>
                    <button className="btn btn-secondary" onClick={() => handleDeleteFunction()}>
                        {t('general:yes')}
                    </button>
                </Modal.Footer>
            </Modal>

            <NavigationBlocker shouldBlock={{ obj1: functionContext.employerFunction, obj2: functionContext.tempChanges }} />
        </div>
    )
}

export default FunctionEdit