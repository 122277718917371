import FormCard from "@/components/cards/form-card";
import { SettingsContext } from "@/context/settings-context";
import { useReminderFrequencyTranslation } from "@/general/i18n/translation-helpers";
import { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { ReminderFrequency } from "../model/reminder-frequency";
import { Settings } from "../model/settings";
import './settings-card.scss';

function CommunicationSettings() {

    const { t } = useTranslation();
    const context = useContext(SettingsContext);
    const { translateReminderFrequency } = useReminderFrequencyTranslation();

    const getReminderFrequencies = () => {
        let frequencies: any[] = [];
        const reminderFrequencies = Object.values(ReminderFrequency).filter(value => !isNaN(Number(value)));
        reminderFrequencies.forEach((f, index) => {
            frequencies.push({ label: translateReminderFrequency(+f), value: f });
        });
        return frequencies;
    }

    const updateTempChanges = (propertyName, newValue) => {

        const updatedSettings: Settings = {
            ...context.tempChanges,
            communication: {
                ...context.tempChanges.communication,
                [propertyName]: newValue
            }
        };

        context.updateChanges(updatedSettings);
    };

    return (
        <FormCard title={t('settings:communication:title')} className="settings-card">
            <p>{t('settings:communication:description')}</p>

            <div className="setting-header mt-4 font-bold">{t('settings:communication:notifyHeader')}</div>

            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('settings:communication:labelNotifyAboutEmployerEvents')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('notifyAboutEmployerEvents', true)}
                                className={`form-toggle-option ${context.tempChanges?.communication?.notifyAboutEmployerEvents ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('notifyAboutEmployerEvents', false)}
                                className={`form-toggle-option ${!context.tempChanges?.communication?.notifyAboutEmployerEvents ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.settings?.communication?.notifyAboutEmployerEvents ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mt-2">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('settings:communication:labelNotifyAboutMatchedTalents')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('notifyAboutMatchedTalents', true)}
                                className={`form-toggle-option ${context.tempChanges?.communication?.notifyAboutMatchedTalents ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('notifyAboutMatchedTalents', false)}
                                className={`form-toggle-option ${!context.tempChanges?.communication?.notifyAboutMatchedTalents ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.settings?.communication?.notifyAboutMatchedTalents ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mt-2">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('settings:communication:labelNotifyAboutSubsidies')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('notifyAboutSubsidies', true)}
                                className={`form-toggle-option ${context.tempChanges?.communication?.notifyAboutSubsidies ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('notifyAboutSubsidies', false)}
                                className={`form-toggle-option ${!context.tempChanges?.communication?.notifyAboutSubsidies ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.settings?.communication?.notifyAboutSubsidies ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mt-4">
                <Col>
                    <Form.Label className={`setting-header ${!context.editable && 'no-edit'}`}>{t('settings:communication:labelReminderFrequencyUpdateFunctions')}</Form.Label>
                    {context.editable ?
                        <Select
                            placeholder={t('settings:communication:placeholderReminderFrequency')}
                            className="frequency-select"
                            value={context.tempChanges?.communication?.reminderFrequencyUpdateFunctions >= 0 ? { label: translateReminderFrequency(context.tempChanges?.communication?.reminderFrequencyUpdateFunctions), value: context.tempChanges?.communication?.reminderFrequencyUpdateFunctions } : null}
                            isClearable={false}
                            isSearchable={true}
                            options={getReminderFrequencies()}
                            onChange={(s) => updateTempChanges('reminderFrequencyUpdateFunctions', s.value)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: '0rem 0.5rem',
                                    boxShadow: 'none'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#0c1732',
                                }),
                            }}
                        /> : <div>{translateReminderFrequency(+context.settings?.communication?.reminderFrequencyUpdateFunctions)}</div>
                    }
                </Col>
            </Form.Group>
        </FormCard>
    )
}

export default CommunicationSettings