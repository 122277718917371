import { FunctionContext } from '@/context/function-context';
import { Feedback } from '@/feedback/models/feedback';
import { FeedbackStatus } from '@/feedback/models/feedback-status';
import { useContext } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmployerFunction } from '../model/employer-function';
import './function-details-card.scss';
import { useFeedbackStatusTranslation } from '@/general/i18n/translation-helpers';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

type FeedbackFormProps = {
    index: number;
    feedbacks: Feedback[];
    onUpdate: (feedbacks: Feedback[]) => void;
};

const FeedbackForm = ({ feedbacks, index, onUpdate }: FeedbackFormProps) => {

    const { t } = useTranslation();
    const { translateFeedbackStatus } = useFeedbackStatusTranslation();

    const handleFeedbackChange = (event: any, index: number, property: string) => {
        feedbacks[index][property] = event.target?.value;
        onUpdate(feedbacks);
    }

    const removeFeedback = (index: number) => {
        feedbacks = feedbacks.filter((item, i) => i !== index);
        onUpdate(feedbacks);
    }

    return (
        <div className={`feedback-item-form pb-4 pb-lg-2`}>

            <div className='d-flex justify-content-between align-items-center mb-2'>
                <div className='h5 font-bold'>
                    {t('functions:detail:feedback:subtitle')} - <span className='text-paars'>{translateFeedbackStatus(feedbacks[index]?.status)}</span>
                </div>
                <Button onClick={() => removeFeedback(index)} className="btn-delete"><i className="fa-solid fa-trash fa-lg" /></Button>
            </div>

            <Form.Group as={Row}>
                <Col sm={12} md={12} lg={6} xl={6} xxl={6} className={`mb-3`}>
                    <Form.Label>{t('functions:detail:feedback:labelName')}</Form.Label>
                    <Form.Control type="text" value={feedbacks[index]?.name ?? ''} onChange={(e) => handleFeedbackChange(e, index, 'name')} />
                </Col>
                <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <Form.Label>{t('functions:detail:feedback:labelEmail')}</Form.Label>
                    <Form.Control type="email" value={feedbacks[index]?.email ?? ''} onChange={(e) => handleFeedbackChange(e, index, 'email')} />
                </Col>
            </Form.Group>
        </div>
    );
};

function FunctionValidators() {

    const { t } = useTranslation();
    const context = useContext(FunctionContext);
    const { translateFeedbackStatus } = useFeedbackStatusTranslation();

    const updateTempChanges = (feedbacks: Feedback[]) => {
        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            feedbacks: [...feedbacks]
        };

        context.updateChanges(updatedFunction);
    }

    const addFeedback = () => {

        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            feedbacks: [
                ...context.tempChanges?.feedbacks ?? [],
                {
                    status: FeedbackStatus.Created,
                    skills: { optional: [], essential: [] },
                    knowledge: { optional: [], essential: [] }
                } as Feedback
            ]
        };

        context.updateChanges(updatedFunction);
    }

    return (
        <Row className='mt-5'>
            <Col xs={12}>
                <div className={`function-validators ${context.editable ? 'p-3 p-md-4 ' : 'px-4 py-4 px-md-5 py-md-5'}`}>
                    <div className='h3 font-bold text-donkerblauw pb-4'>{t('functions:detail:feedback:title')}</div>
                    <p className='mb-4'>{t('functions:detail:feedback:description')}</p>

                    {context.editable &&
                        <>
                            {(!context.tempChanges?.feedbacks || context.tempChanges?.feedbacks?.length === 0) &&
                                <div className='field view'>{t('functions:detail:feedback:noFeedback')}</div>
                            }
                            {context.tempChanges?.feedbacks?.map((item, i) => (
                                <FeedbackForm feedbacks={context.tempChanges?.feedbacks} index={i} onUpdate={updateTempChanges} key={i} />
                            ))}
                            <Row>
                                <Col className='mt-4'>
                                    <Button onClick={addFeedback} className="btn btn-primary-outline"><i className="fa-solid fa-plus fa-lg me-2" />{t('functions:detail:feedback:addFeedback')}</Button>
                                </Col>
                            </Row>
                        </>
                    }

                    {!context.editable &&
                        <div>
                            {(!context.employerFunction?.feedbacks || context.employerFunction?.feedbacks?.length === 0) &&
                                <div className='field view'>{t('functions:detail:feedback:noFeedback')}</div>
                            }

                            {context.employerFunction?.feedbacks?.sort((a, b) => a.status - b.status)?.map((item, i) => (
                                <Row key={i} className='feedback-item mt-3'>
                                    <Col sm={12} md={3} lg={3} xl={3}>
                                        <span className="text-paars font-bold">{translateFeedbackStatus(item.status)}</span>
                                    </Col>
                                    <Col sm={12} md={3} lg={3} xl={3}>
                                        {
                                            (item.status === FeedbackStatus.Submitted || item.status === FeedbackStatus.Reviewed)
                                                ? <Link to={`/profile/functions/${context.employerFunction?.id}/feedback/${item.id}`} className="link">{t('functions:detail:feedback:linkToFeedback')}</Link>
                                                : <span className="link disabled">{t('functions:detail:feedback:linkToFeedback')}</span>
                                        }
                                    </Col>
                                    <Col sm={12} md={3} lg={3} xl={3}>
                                        <span>{item.name}</span>
                                    </Col>
                                    <Col sm={12} md={3} lg={3} xl={3}>
                                        <span className='text-break'>{t('functions:detail:feedback:updatedOn')}: {dayjs(item.createdOn).format("DD-MM-YYYY")}</span>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    }
                </div>
            </Col>
        </Row>
    );
}

export default FunctionValidators;