import { Navigate, Route, Routes } from 'react-router-dom';
import CompanyEdit from './components/company-edit';
import CompanyAdd from './components/company-add';

function CompanyModule() {

    return (
        <Routes>
            <Route path="/" element={<Navigate to="edit" replace />} />
            <Route path="add" element={<CompanyAdd />} />
            <Route path="edit" element={<CompanyEdit />} />
        </Routes>
    )
}

export default CompanyModule;