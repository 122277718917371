import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react"
import { useIdleTimer } from "react-idle-timer"

const useIdleDetection = () => {

    const timeout = 1 * 60 * 60 * 1000; // 1hr x 60min x 60sec x 1000ms = 1hr

    const [remaining, setRemaining] = useState<number>(timeout);
    const { instance } = useMsal();

    const { getRemainingTime } = useIdleTimer({
        timeout: timeout,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));            
        }, 500)

        if (remaining === 0) {
            
            if (instance.getActiveAccount()) {
                instance.logoutRedirect();
            }
        }        

        return () => {
            clearInterval(interval)
        }
    })
}

export default useIdleDetection;