import CompanyModule from '@/company/company-module';
import { ADErrorComponent } from '@/components/active-directory/ad-error-component';
import { ADLoadingComponent } from '@/components/active-directory/ad-loading-component';
import PageNotFound from '@/components/not-found-component/page-not-found';
import CompanyProvider from '@/context/company-provider';
import { ConfigContext } from '@/context/config-context';
import FeedbackContainer from '@/feedback/feedback-container';
import ConnectorsModule from '@/connectors/connectors-module';
import { GeneralSWRConfig } from '@/general/general-swr-config';
import AboutUsPage from '@/pages/about-us';
import ContactPage from '@/pages/contact';
import FaqPage from '@/pages/faq';
import Homepage from '@/pages/home';
import SettingsModule from '@/settings/settings-module';
import AdminModule from '@/admin/admin-module';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { useContext } from 'react';
import { Navigate, Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import PlatformLayout from './platform-layout';
import PublicLayout from './public-layout';
import WelcomePage from '@/pages/welcome';
import ShopModule from '@/shop/shop-module';

const AppRouter = () => {

    var configContext = useContext(ConfigContext);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
               
                    <Route path="/" element={<PublicLayout />}>
                        <Route path="/" index element={<Homepage />} />
                        <Route path="/shop" index element={
                            <GeneralSWRConfig>
                                <ShopModule />
                            </GeneralSWRConfig>
                        } 
                        />
                        <Route path="/faq" element={<FaqPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/about-us" element={<AboutUsPage />} />
                        <Route path="/feedback" element={<FeedbackContainer />} />
                    </Route>

                <Route path="/profile/*" element={
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={{ scopes: [...configContext?.configBody?.api?.endpoints?.scopes] }}
                        errorComponent={ADErrorComponent}
                        loadingComponent={ADLoadingComponent}
                    >
                        <GeneralSWRConfig>
                            <CompanyProvider>
                                <Routes>
                                    <Route path="/" element={<PlatformLayout />}>
                                        <Route path="/" index element={<Navigate to={"/profile/detail/add"} />} />
                                        <Route path="/welcome" element={<WelcomePage />} />
                                        <Route path="/detail/*" element={<CompanyModule />} />
                                        <Route path="/connectors/*" element={<ConnectorsModule />} />
                                        <Route path="/settings" element={<SettingsModule />} />
                                        <Route path="/admin" element={<AdminModule />} />
                                        <Route path="*" element={<PageNotFound />} />
                                    </Route>
                                </Routes>
                            </CompanyProvider>
                        </GeneralSWRConfig>
                    </MsalAuthenticationTemplate>
                } />

                <Route path="*" element={<PageNotFound />} />
            </Route>
        )
    );

    return (
        <RouterProvider router={router} />
    )
}

export default AppRouter;

