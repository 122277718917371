import { Container } from 'react-bootstrap'
import './footers.scss'
import { t } from 'i18next'

function PartnerFooter() {
    return (
        <Container fluid className={`bg-wit`}>
            <Container className='partner-footer d-flex align-items-center justify-content-center flex-column flex-md-row'>

                <p className='font-bold me-0 me-md-4 mt-4 mt-md-0'>{t('public:footer:inPartnershipWith')}</p>

                <div className='partners d-flex align-items-center justify-content-center flex-column flex-md-row'>
                    <a aria-label="Bekijk de website" href="https://pulse.microsoft.com/nl-nl/tech-banen-als-sleutel-tot-economisch-herstel/" target="http://_blank">
                        <img src="/assets/img/logo-microsoft.svg" alt="" />
                    </a>
                    <a aria-label="Bekijk de website" href="https://linkedin.com/" target="http://_self">
                        <img src="/assets/img/logo-linkedin.svg" alt="" />
                    </a>
                </div>

            </Container>
        </Container>
    )
}

export default PartnerFooter