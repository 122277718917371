import { Container, Dropdown } from 'react-bootstrap'
import { CompanyContext } from '@/context/company-context';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import './navigation-bar.scss';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import NavLogin from './components/nav-login';

function NavigationBar() {

    const { i18n, t } = useTranslation();
    const companyContext = useContext(CompanyContext);
    const [mobilePopupOpen, setMobilePopupOpen] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleLanguageChange = (languageCode: string) => {

        if (languageCode === i18n.language) { return; };

        i18n.changeLanguage(languageCode);
        dayjs.locale(languageCode);
        localStorage.setItem('selectedLanguage', languageCode);
        toast.success(t('languages:toasts:languageChanged', { language: t(`languages:${languageCode}`) }));
    };

    useEffect(() => {
        // Function to close popup when clicking outside
        const closePopup = (event) => {

            // Check if the click occurred on the button to open the popup
            if (event.target.closest('.more-button')) {
                return;
            }

            // Check if the click occurred on a link within the popup
            if (event.target.closest('.mobile-popup a')) {
                setMobilePopupOpen(false);
                return;
            }

            if (!event.target.closest('.mobile-popup') && mobilePopupOpen) {
                setMobilePopupOpen(false);
            }
        };

        // Add event listener
        document.addEventListener('click', closePopup);

        // Cleanup function
        return () => {
            document.removeEventListener('click', closePopup);
        };
    }, [mobilePopupOpen]);

    useEffect(() => {
        // Add className to body when company is selected to apply extra margin because of navbar
        if (companyContext.company) {
            document.body.classList.add('navbar-margin');
        } else {
            document.body.classList.remove('navbar-margin');
        }
    }, [companyContext.company]);

    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef(null);

    // handle scroll event
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.pageYOffset > (elTopOffset + elHeight)) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };

    // add/remove scroll event listener
    useEffect(() => {
        var header = headerRef.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height)
        }

        window.addEventListener('scroll', handleScrollEvent);

        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);


    return (
        <header className={`${sticky.isSticky ? ' sticky' : ''} ${menuIsOpen ? 'open-nav' : ''}`} ref={headerRef}>
            <nav className="navbar navbar-shop navbar-expand-lg transition position-absolute bg-wit py-0 w-100">
                <div className="container">
                    <div className="navbar-brand position-relative">
                        <Link to='/'>
                            <img src={mobilePopupOpen ? `https://sparsedevelopment.nl/wp-content/themes/sparse/assets/img/sparse-icon-stekker.svg` : `https://sparsedevelopment.nl/wp-content/themes/sparse/assets/img/sparse-icon-stekker.svg`} alt="Sparse" className="img-fluid position-absolute sparse-powerconnector" width="100" height="100" /> <span className="sparse-title text-zwart h3 d-none d-xl-inline-block">Powerconnector</span>
                        </Link>
                    </div>

                    <div className={`navbar-toggler ${menuIsOpen ? 'open-nav' : ''}`} aria-label="Toggle navigation" role="button" onClick={() => setMenuIsOpen(!menuIsOpen)}>
                        <span className="transition"></span>
                    </div>

                    <div className={`nav-menu transition ${menuIsOpen ? 'open-nav' : ''}`}>
                        <div className="sparse-icon d-block d-lg-none">
                            <a href="https://sparsedevelopment.nl">
                                <img src="https://sparsedevelopment.nl/wp-content/themes/sparse/assets/img/sparse-icoon-diapositief.svg" alt="Sparse" className="img-fluid position-absolute transition" width="75" height="75" />
                            </a>
                        </div>

                        <ul id="menu-hoofdmenu" className="nav navbar-nav ml-auto d-flex">

                            <li className="menu-item nav-item">
                                <NavLink
                                    to="/shop"
                                    className="nav-link"
                                >
                                    <span>{t('navigationBar:connectors')}</span>
                                </NavLink>
                            </li>

                            <li className="menu-item nav-item">
                                <NavLink
                                    to="/profile/detail"
                                    className="nav-link"
                                >
                                    <span>{t('navigationBar:company')}</span>
                                </NavLink>
                            </li>

                            <li className="menu-item nav-item">
                                <a href="#" className="nav-link">
                                    <span>Over ons</span>
                                </a>
                            </li>

                            <li className="menu-item nav-item">
                                <a href="#" className="nav-link">
                                    <span>Contact</span>
                                </a>
                            </li>

                            <NavLogin />

                            <li className="menu-item nav-item">
                                <Dropdown className="menu-item wpml-ls-current-language wpml-ls-menu-item menu-item-has-children dropdown menu-item-wpml-ls-3-nl nav-item" drop="down-centered">
                                    <Dropdown.Toggle className="dropdown-toggle nav-link">
                                        <span>{i18n.language}</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleLanguageChange("en")}>{t('languages:en')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleLanguageChange("nl")}>{t('languages:nl')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <nav className={`navbar navbar-expand-lg transition fixed-top bg-wit py-2 py-md-3 w-100 ${menuIsOpen ? 'open-nav' : ''}`} >
                <div className="container">
                    <div className="navbar-brand">
                        <img src="https://sparsedevelopment.nl/wp-content/themes/sparse/assets/img/sparse-icoon-fullcolor.svg" alt="Sparse" className="img-fluid transition position-absolute" width="97" height="97" />
                    </div>

                    <div className={`navbar-toggler ${menuIsOpen ? 'open-nav' : ''}`} aria-label="Toggle navigation" role="button">
                        <span className="transition"></span>
                    </div>

                    <div className={`nav-menu transition ${menuIsOpen ? 'open-nav' : ''}`}>
                        <div className="sparse-icon d-block d-lg-none">
                            <img src="https://sparsedevelopment.nl/wp-content/themes/sparse/assets/img/sparse-icoon-diapositief.svg" alt="Sparse" className="img-fluid position-absolute transition" width="75" height="75" />
                        </div>

                        <ul id="menu-hoofdmenu-sticky" className="nav navbar-nav ml-auto d-flex">
                            <li className="menu-item nav-item">
                                <NavLink
                                    to="/shop"
                                    className="nav-link"
                                >
                                    <span>{t('navigationBar:connectors')}</span>
                                </NavLink>
                            </li>

                            <li className="menu-item nav-item">
                                <NavLink
                                    to="/profile/detail"
                                    className="nav-link"
                                >
                                    <span>{t('navigationBar:company')}</span>
                                </NavLink>
                            </li>

                            <li className="menu-item nav-item">
                                <a href="#" className="nav-link">
                                    <span>Over ons</span>
                                </a>
                            </li>

                            <li className="menu-item nav-item">
                                <a href="#" className="nav-link">
                                    <span>Contact</span>
                                </a>
                            </li>

                            <NavLogin />
                            
                            <Dropdown className="language-switch" drop="down-centered">
                                <Dropdown.Toggle className="language-toggle">
                                    <span>{i18n.language}</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleLanguageChange("en")}>{t('languages:en')}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleLanguageChange("nl")}>{t('languages:nl')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <li className="menu-item wpml-ls-current-language wpml-ls-menu-item menu-item-has-children dropdown menu-item-wpml-ls-3-nl nav-item">
                                <a title="NL" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle nav-link">
                                    <span>
                                        <span lang="nl">NL</span>
                                    </span>
                                </a>

                                <ul className="dropdown-menu">
                                    <li className="menu-item nav-item">
                                        <a title="EN" href="#" className="dropdown-item">
                                            <span>
                                                <span lang="en">EN</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

    );
}

export default NavigationBar;
