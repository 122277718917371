import { useIsAuthenticated } from '@azure/msal-react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PageFooter from '../footers/page-footer'
import PublicNavigationBar from '../public-navigation-bar/public-navigation-bar'
import './page-not-found.scss'

function PageNotFound() {

    const isAuthenticated = useIsAuthenticated();

    return (
        <div className="page-not-found">
            <div className='position-relative'>

                <div className='page-not-found__bg-img'>
                    <img src='/assets/img/visual-error.jpg' alt='' />
                </div>

                <PublicNavigationBar lightTheme={true} />

                <Container fluid>
                    <Container>
                        <Row className="page-not-found__content pt-5 pb-4">
                            <Col sm={12} md={6} lg={6} xl={6}>
                                <h1 className='h1 font-heavy mb-4'>Oeps...</h1>
                                <p className='mb-4'>We kunnen deze pagina niet meer vinden. Je kan het via de navigatie opnieuw proberen.</p>
                                {
                                    isAuthenticated ?
                                        <Link to='/profile/' className='btn btn-primary'>Ga naar je profiel</Link>
                                        :
                                        <Link to='/' className='btn btn-primary'>Terug naar home</Link>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
            <PageFooter />
        </div>
    )
}

export default PageNotFound