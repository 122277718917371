import { APIConfig } from "@/context/config-context";
import axios from "axios";

export function getTranslations(apiConfig: APIConfig): Promise<any> {

    return axios.get(apiConfig.publicBaseUrl + apiConfig.endpoints.general.translations, 
        {
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(response => {                                
            return response.data;
        })
        .catch(e => console.error(`Failed to load translations`, e))
};