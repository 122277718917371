import { useEffect, useState } from 'react';
import './scroll-to-top.scss';

const ScrollToTopButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 400) {
            setVisible(true)
        }
        else if (scrolled <= 400) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);

        return () => {
            window.removeEventListener('scroll', toggleVisible);
        };
    }, []);

    return (
        <button tabIndex={-1} onClick={scrollToTop} className={`scroll-to-top ${visible ? '' : 'd-none'}`}>
            <i className="fas fa-chevron-up fa-xl"></i>
        </button>
    );
}

export default ScrollToTopButton; 