import React, { useContext, useState } from "react";
import { ConnectorTeamleaderFocusContext, IConnectorTeamleaderFocusState } from "./connector-teamleaderfocus-context";
import { TeamleaderFocusAuthorization } from "@/connectors/model/teamleader-focus/teamleader-focus-authorization";

function ConnectoreTeamleaderFocusProvider({ children }): React.ReactElement {

    const [contextValue, setContextValue] = useState<IConnectorTeamleaderFocusState>({
        updateAutorizationInfo: updateAutorizationInfo,
        authorizationInfo: {},
    });

    function updateAutorizationInfo(autorizationInfo: TeamleaderFocusAuthorization) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                autorizationInfo: autorizationInfo,
            };
        });
    }

    return (
        <ConnectorTeamleaderFocusContext.Provider value={contextValue}>
            {children}
        </ConnectorTeamleaderFocusContext.Provider>
    );
}

export default ConnectoreTeamleaderFocusProvider;