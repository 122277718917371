import PageFooter from "@/components/footers/page-footer";
import PageHeader from "@/components/page-header/page-header";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import './pages.scss';

function AboutUsPage() {
    const { t } = useTranslation();
    useDocumentTitle(t('public:documentTitles:aboutUs'));

    return (
        <div className="app-container">
            <div className="main-container">
                <main className="public about-us">
                    <PageHeader
                        title={t('public:aboutUsPage:title')}
                        imgSrc="/assets/img/afbeelding-over-ons.jpg"
                        description={t('public:aboutUsPage:description')}
                        link="/profile/detail/"
                    />

                    <Container fluid className='bg-lichtblauw'>
                        <Container>
                            <Row className="pt-5 pb-4">
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0 position-relative">
                                    <div className="img left position-absolute">
                                        <img src="/assets/img/afbeelding-het-initiatief.jpg" className="img-fluid" alt='' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0">
                                    <div className="textblock right p-4 p-md-5">
                                        <h2>{t('public:aboutUsPage:initiativeTitle')}</h2>
                                        <p className="my-4">{t('public:aboutUsPage:initiativeDescription')}</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <div className="textblock full bg-paars py-4 py-md-5 text-center default-br">
                                        <h5 className="text-wit font-heavy">{t('public:aboutUsPage:quote')}</h5>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="pt-5 pb-5">
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0 position-relative">
                                    <div className="img left position-absolute">
                                        <img src="/assets/img/afbeelding-doelen.jpg" className="img-fluid" alt='' />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} xl={6} className="px-0">
                                    <div className="textblock right p-4 p-md-5">
                                        <p className="my-4">{t('public:aboutUsPage:goalsDescription')}</p>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </Container>
                </main>
                <PageFooter />
            </div>
        </div>
    )
}

export default AboutUsPage;