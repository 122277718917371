import React, { useContext, useState } from "react";
import { ConnectorExactContext, IConnectorExactState } from "./connector-exact-context";
import { ExactGlobeDatabaseInfo } from "@/connectors/model/exactglobe/exactglobe-database-info";

function ConnectorExactProvider({ children }): React.ReactElement {

    const [contextValue, setContextValue] = useState<IConnectorExactState>({
        updateDatabaseInfo: updateDatabaseInfo,
        databaseInfo: {},
    });

    function updateDatabaseInfo(databaseInfo: ExactGlobeDatabaseInfo) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                databaseInfo: databaseInfo,
            };
        });
    }

    return (
        <ConnectorExactContext.Provider value={contextValue}>
            {children}
        </ConnectorExactContext.Provider>
    );
}

export default ConnectorExactProvider;