import { t } from 'i18next';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

const useFavoriteTalents = () => {
    const [favorites, setFavorites] = useState<string[]>([]);

    const updateFavorites = () => {
        const favoritesFromStorage = JSON.parse(localStorage.getItem('favorites') || '[]');
        setFavorites(favoritesFromStorage);
    };

    function clearFavorites() {
        localStorage.removeItem('favorites');
    }

    function toggleFavorite(id: string) {
        const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');

        if (favorites.includes(id)) {
            const newFavorites = favorites.filter(favoriteId => favoriteId !== id);
            localStorage.setItem('favorites', JSON.stringify(newFavorites));
        } else {
            favorites.push(id);
            localStorage.setItem('favorites', JSON.stringify(favorites));
            toast.success(t('talents:toasts:favoriteAdded'));
        }

        updateFavorites();
    }

    useEffect(() => {
        updateFavorites();

        const handleStorageChange = (e) => {
            if (e.key === 'favorites') {
                updateFavorites();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return { toggleFavorite, clearFavorites, favorites };
}

export default useFavoriteTalents;