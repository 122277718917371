import FormCard from '@/components/cards/form-card';
import { FunctionContext } from '@/context/function-context';
import { useEducationLevelTranslation } from '@/general/i18n/translation-helpers';
import { useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EducationLevel } from '../model/education-level';
import { EmployerFunction } from '../model/employer-function';
import './function-details-card.scss';

function FunctionRequirements() {

    const { t } = useTranslation();
    const { translateEducationLevel } = useEducationLevelTranslation();
    const context = useContext(FunctionContext);
    const educationLevels = Object.values(EducationLevel).filter(value => !isNaN(Number(value)));

    const updateTempChanges = (propertyName, newValue) => {

        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            [propertyName]: newValue
        };

        context.updateChanges(updatedFunction);
    };

    return (
        <FormCard title={t('functions:detail:requirements:title')}>
            <Form.Group as={Row} className="">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:requirements:labelRequiredExperienceInYears')}</Form.Label>
                    {context.editable ?
                        <Form.Control type="number" min={0} placeholder="" value={context.tempChanges?.requiredExperienceInYears || ''} onChange={e => updateTempChanges('requiredExperienceInYears', e.target.value)} />
                        : <div>{context.employerFunction?.requiredExperienceInYears}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:requirements:labelRequiredEducationLevel')}</Form.Label>
                    {context.editable ?
                        <Form.Select
                            aria-label={t('functions:detail:requirements:placeholderRequiredEducationLevel')}
                            value={context.tempChanges?.requiredEducationLevel || 0}
                            onChange={(e) => {
                                if (e.target.selectedIndex == 0) { return; }
                                updateTempChanges('requiredEducationLevel', e.target.value)
                            }}
                        >
                            <option key={-1} value="">{t('functions:detail:requirements:placeholderRequiredEducationLevel')}</option>
                            {educationLevels.map((level, index) => (
                                <option key={index} value={level}>
                                    {translateEducationLevel(+level)}
                                </option>
                            ))}
                        </Form.Select>
                        : <div>{translateEducationLevel(context.employerFunction?.requiredEducationLevel)}</div>
                    }
                </Col>
            </Form.Group>
        </FormCard>
    );
}

export default FunctionRequirements;