import AdminOverview from './admin-overview';

function AdminModule() {

    return (
        <AdminOverview />
    )
}


export default AdminModule;