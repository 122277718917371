import PageFooter from '@/components/footers/page-footer';
import PageHeader from '@/components/page-header/page-header';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { t } from 'i18next';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './pages.scss';

function FaqPage() {

    useDocumentTitle(t('documentTitles:faq'));

    const faqData = t('public:faq:faqData', { returnObjects: true }) as any[];

    return (
        <div className="app-container">
            <div className="main-container">
                <main className="public faq">

                    <PageHeader
                        title={t('public:faq:headerTitle')}
                        imgSrc="/assets/img/header-beroep-data-analist.jpg"
                        description={
                            <>
                                {t('public:faq:description.part1')} <Link className="link" to="/contact">{t('public:faq:description.contactLink')}</Link> {t('public:faq:description.part2')}
                            </>
                        }
                    />

                    <Container fluid className='bg-lichtblauw'>
                        <Container>

                            <Row className="py-5">
                                {
                                    faqData?.map((faq, index) => {
                                        return (
                                            <Col sm={12} md={12} lg={12} xl={12} className="px-0 mb-5" key={index}>
                                                <h2 className='font-heavy mb-3'>{faq.title}</h2>
                                                <Accordion>
                                                    {
                                                        faq.questions.map((question, index) => {
                                                            return (
                                                                <Accordion.Item eventKey={index.toString()} key={index}>
                                                                    <Accordion.Header>
                                                                        {question.question}
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {question.answer}
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            )
                                                        })
                                                    }
                                                </Accordion>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>

                            <Row className='pb-5'>
                                <Col sm={12} md={12} lg={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} className="p-4 p-md-5 bg-wit default-br">
                                    <h2 className='font-heavy text-oranje text-center mb-3'>{t('public:faq:questionNotFound')}</h2>
                                    <p>
                                        {t('public:faq:contactDescription')}
                                        <a className='ms-1 text-decoration-underline' href="mailto:info@werkinhetvooruitzicht.nl">info@werkinhetvooruitzicht.nl</a>
                                    </p>
                                    <p className='text-center pt-4'>
                                        {t('public:faq:thanksMessage')}
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </main>

                <PageFooter />
            </div>
        </div>
    )
}

export default FaqPage