import { ConfigContext } from '@/context/config-context';
import { FunctionProfession } from '@/connectors/model/function-profession';
import debounce from "debounce-promise";
import { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { SingleValue } from "react-select/dist/declarations/src/types";
import { ProfessionSearchDocument } from './profession-search-document';
import './search-bar.scss';

type SearchBarProps = {
    profession?: FunctionProfession;
    onProfessionChange: (s: FunctionProfession) => void;
}

export default function ProfessionSingleSearchBar({profession, onProfessionChange}: SearchBarProps) {
    const {t} = useTranslation();
    const configContext = useContext(ConfigContext);

    const [selectedOption, setSelectedOption] = useState<SingleValue<any>[]>([]);

    useEffect(() => {

        if (profession?.professionId) {

            const initSelectedOption = {
                value: {
                    name: profession.name,
                    id: profession.professionId
                } as ProfessionSearchDocument,
                label: profession.name
            };

            setSelectedOption(initSelectedOption as unknown as SingleValue<any>[]);
        }
    }, [profession]);

    const handleCreatedOption = (inputValue: string) => {
        
        if (inputValue) {

            const selectedProfession = {
                name: inputValue,
                professionId: null
            } as FunctionProfession;

            setSelectedOption({label: inputValue, value: null} as unknown as SingleValue<any>[]);
            onProfessionChange(selectedProfession);
            return;
        }
    }

    const handleSelectedOption = (selectedOption: any) => {

        if (!selectedOption) {
            setSelectedOption([] as unknown as SingleValue<any>[]);
            onProfessionChange(null);
            return;
        }
        
        setSelectedOption(selectedOption);

        const selectedProfession = {
            name: selectedOption.label,
            professionId: selectedOption.value?.id
        } as FunctionProfession;
        
        onProfessionChange(selectedProfession);
    }

    const _loadProfessionSuggestions = (inputValue: string) => {

        const savedLanguage = localStorage.getItem('selectedLanguage') ?? 'nl';

        const body = {
            filter: `language eq '${savedLanguage}'`,
            select: 'name, id, skills, knowledge, description, professionName',
            search: inputValue,
            suggesterName: 'esco-profession-suggester',
            top: 15
        };

        return fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.search.professions}/suggest`, {
            credentials: "same-origin",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .then((result) => {
                let groupedResults = result.value.reduce((acc: any, item: ProfessionSearchDocument) => {
                    const key = item.professionName;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(item);
                    return acc;
                }, {});

                return Object.keys(groupedResults).map((key) => {
                    return {
                        label: key,
                        options: groupedResults[key].map((item: ProfessionSearchDocument) => {
                            return {
                                value: item,
                                label: item.name
                            };
                        })
                    };
                });
            })
            .catch(
                (error) => {

                });
    }

    const loadProfessionSuggestions = debounce(_loadProfessionSuggestions, 300);

    return (
        <Form.Group as={Row} className='profession-search mt-4'>
            <Col>
                <Form.Label>{t('functions:detail:labelName')}</Form.Label>
                <AsyncCreatableSelect
                    isMulti={false}
                    isClearable
                    placeholder={t('functions:detail:placeholderSearch')}
                    value={selectedOption}
                    onChange={(s) => handleSelectedOption(s)}
                    onCreateOption={(s) => handleCreatedOption(s)}
                    defaultOptions={false}
                    loadOptions={loadProfessionSuggestions}
                    required={true}
                    hideSelectedOptions={true}
                    className='p-0'
                    formatCreateLabel={(inputValue) => <span role='button'>{t('functions:detail:createFunction')} "<span className='font-bold'>{inputValue}</span>"</span>}
                    noOptionsMessage={(e) => e.inputValue ? t('functions:detail:professionSearch:noProfessionsFound') : null}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isDisabled ? '' : 'none',
                            borderRadius: '26px',
                            padding: '0.5rem',
                            boxShadow: 'none'
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: 'black',
                            cursor: 'pointer'
                        }),
                    }}/>
            </Col>
        </Form.Group>
    );
};