import { SettingsContext } from "@/context/settings-context";
import { t } from "i18next";
import { useContext } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import './settings-card.scss';
import { useTranslation } from "react-i18next";
import { Settings } from "../model/settings";
import FormCard from "@/components/cards/form-card";

function PrivacySettings() {

    const { t } = useTranslation();
    const context = useContext(SettingsContext);

    const updateTempChanges = (propertyName, newValue) => {

        const updatedSettings: Settings = {
            ...context.tempChanges,
            privacy: {
                ...context.tempChanges.privacy,
                [propertyName]: newValue
            }
        };

        context.updateChanges(updatedSettings);
    };

    return (
        <FormCard title={t('settings:privacy:title')} className="settings-card">
            <p>{t('settings:privacy:description')}</p>

            <div className="category-header mt-4 font-bold">{t('settings:privacy:allowHeader')}</div>

            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('settings:privacy:labelAllowCompanyReference')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('allowCompanyReference', true)}
                                className={`form-toggle-option ${context.tempChanges?.privacy?.allowCompanyReference ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('allowCompanyReference', false)}
                                className={`form-toggle-option ${!context.tempChanges?.privacy?.allowCompanyReference ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.settings?.privacy?.allowCompanyReference ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mt-2">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('settings:privacy:labelAllowDataUseForTalentAcquisition')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('allowDataUseForTalentAcquisition', true)}
                                className={`form-toggle-option ${context.tempChanges?.privacy?.allowDataUseForTalentAcquisition ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('allowDataUseForTalentAcquisition', false)}
                                className={`form-toggle-option ${!context.tempChanges?.privacy?.allowDataUseForTalentAcquisition ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.settings?.privacy?.allowDataUseForTalentAcquisition ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mt-2">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('settings:privacy:labelAllowDataStorageForRecruitmentAndStatistics')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('allowDataStorageForRecruitmentAndStatistics', true)}
                                className={`form-toggle-option ${context.tempChanges?.privacy?.allowDataStorageForRecruitmentAndStatistics ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('allowDataStorageForRecruitmentAndStatistics', false)}
                                className={`form-toggle-option ${!context.tempChanges?.privacy?.allowDataStorageForRecruitmentAndStatistics ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.settings?.privacy?.allowDataStorageForRecruitmentAndStatistics ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>
        </FormCard>
    )
}

export default PrivacySettings