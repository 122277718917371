import { ConfigContext } from '@/context/config-context';
import useAuthenticatedPost from '@/hooks/useAuthenticatedPost';
import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { ExactGlobeDatabaseInfo } from "@/connectors/model/exactglobe/exactglobe-database-info";
import { Handler, useWizard } from "@/components/wizard";
import { ConnectorExactContext } from '@/connectors/components/connector-exact/context/connector-exact-context';
import { CopyBlock } from 'react-code-blocks';
import { a11yDark } from 'react-code-blocks'
import { LoadingComponent } from '@/components/loading-component/loading-component';

function CheckConnection() {

    const { handleStep, activeStep } = useWizard();
    const connectorExactContext = useContext(ConnectorExactContext);
    const { postRequest } = useAuthenticatedPost();
    const configContext = useContext(ConfigContext);
    const [checkConnection, setCreatingConnector] = useState(false);
    const formRef = createRef<HTMLFormElement>();

    const { data, isLoading, error, mutate } = useSWR(configContext.configBody.api.endpoints.connectors.setup.replace('{name}', 'exactglobe').replace('{step}', 'installinfo'), { shouldRetryOnError: true });


    useEffect(() => {
        document.body.classList.add('no-padding');
        console.log('creating connector');


        createConnector();

        return () => {
            document.body.classList.remove('no-padding');
        };
    }, []);

    handleStep(async () => {
        return !checkConnection;
    });

    const createConnector = async () => {
        setCreatingConnector(true);
        postRequest(configContext.configBody.api.endpoints.connectors.setup.replace('{name}', 'exactglobe').replace('{step}', 'createconnector'), {})
            .then((response) => {
                toast.success(t('connectors:exactglobe:message:saveSuccess'));
                mutate(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('company:transferRequest:acceptError'));
                throw error;
            })
            .finally(() => {
                setCreatingConnector(false);
            });

    };

    return (
        <>
            <Row className='position-relative pt-5'>
                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <h2>
                        {t('connectors:exactglobe:add:description')}
                    </h2>
                </Col>
            </Row>
            <Row className='position-relative pt-3'>
                <Col sm={12} md={12} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 5, offset: 1 }}>
                    {checkConnection ? <LoadingComponent message={t('connectors:exactglobe:add:checkingConnection')} />
                        : <div className="alert alert-success" role="alert">{t('connectors:exactglobe:add:connectionSuccesful')}</div>
                    }
                </Col>
            </Row>
        </>
    )
}

export default CheckConnection