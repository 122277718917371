import PageFooter from "@/components/footers/page-footer"
import PageHeader from "@/components/page-header/page-header"
import SwiperComponent from "@/components/swiper-component/swiper-component"
import { useDocumentTitle } from "@/hooks/useDocumentTitle"
import { t } from "i18next"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { SwiperSlide } from "swiper/react"
import './pages.scss'

function Homepage() {
    useDocumentTitle(t('documentTitles:home'));

    const testimonials = t('public:testimonials', { returnObjects: true }) as any[];

    return (
        <div className="page-content bg-lichtgrijs pb-5">
				<div className="container pt-5 py-lg-5">
					<div className="row row-vh-75 align-items-center pt-5 pb-4">
						<div className="col-sm-12 text-center pt-4 pt-lg-0">
							<h1>Koppel snel al jouw gegevens.</h1>
							<form className="product-search my-4">
								<div className="form-top d-flex align-items-center justify-content-center">
									<span className="h4">Ik wil</span>
									<input type="text" className="form-control mb-0 mx-3 w-auto" id="Product" placeholder="Inschrijvingen..." />
									<span className="h4">koppelen en maak gebruik van</span>
								</div>
								
								<div className="form-bottom d-flex align-items-center justify-content-center mt-4">
									<div className="form-check me-4 me-lg-5">
										<input className="form-check-input" type="radio" name="selectProduct" id="Copilot" />
										<label className="form-check-label" ><img src="https://sparsedevelopment.nl/wp-content/themes/sparse/assets/img/sparse-icon-copilot.png" alt="Logo Copilot" width="40" height="40" className="img-fluid me-2"/>Copilot</label>
									</div>

									<div className="form-check">
										<input className="form-check-input" type="radio" name="selectProduct" id="PowerPlatform" />
										<label className="form-check-label" ><img src="https://sparsedevelopment.nl/wp-content/themes/sparse/assets/img/sparse-icon-power-platform.png" alt="Logo Power Platform" width="40" height="40" className="img-fluid me-2" />Power Platform</label>
									</div>
								</div>
							</form>
							<a href="/shop" className="btn btn-paars mt-4">Bekijk jouw koppelingen</a>
						</div>
					</div>
					
					<div className="row mt-4 mt-md-5">
						<div className="col-12 col-lg-8 offset-lg-2">
							<div className="text-boxshadow bg-wit p-4 p-lg-5">
								<h2 className="text-groen">Ruimte voor extra tekst</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eu venenatis leo, a aliquam orci. Maecenas eu pretium risus. Mauris efficitur est sed ipsum tempor hendrerit. Vestibulum aliquam lorem dui. Pellentesque dolor dui, scelerisque ut tempor non, facilisis non tortor. Etiam ultrices erat non mi viverra dapibus.</p>
							</div>
						</div>
					</div>
					
					<div className="row mt-4 mt-md-5">
						<div className="col-12 col-lg-8 offset-lg-2">
							<div className="text-boxshadow bg-wit p-4 p-lg-5">
								<h2 className="text-groen">Ruimte voor extra tekst</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eu venenatis leo, a aliquam orci. Maecenas eu pretium risus. Mauris efficitur est sed ipsum tempor hendrerit. Vestibulum aliquam lorem dui. Pellentesque dolor dui, scelerisque ut tempor non, facilisis non tortor. Etiam ultrices erat non mi viverra dapibus.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
    )
}

export default Homepage