import ScrollToTopNavigation from "@/components/scroll-to-top/scroll-to-top-navigation";
import useIdleDetection from "@/hooks/useIdleDetection";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import './platform-layout.scss';
import ScrollToTopButton from "@/components/scroll-to-top/scroll-to-top-button";
import PublicNavigationBar from "@/components/public-navigation-bar/public-navigation-bar";

function PublicLayout() {

    useIdleDetection();

    return (
        <>
            <ScrollToTopButton />
            <ScrollToTopNavigation />
            <PublicNavigationBar />
            
            <main>
                <Outlet />
            </main>
            <Toaster
                position="bottom-center"
                gutter={8}
                toastOptions={
                    {
                        duration: 5000,
                        success: {
                            duration: 3000,
                        },
                    }
                }
            />
        </>
    );
}

export default PublicLayout;