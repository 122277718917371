import { ConfigContext } from "@/context/config-context";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MenuLogoutIcon } from "./menu-icons";

function NavLogin() {

    var configContext = useContext(ConfigContext);
    const { t } = useTranslation();
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const login = () => {
        instance.loginRedirect({ scopes: [...configContext?.configBody?.api?.endpoints?.scopes], redirectStartPage: '/profile' })
            .catch((error) => console.log(error));
    }

    const logout = () => {
        instance.logout()
            .catch((error) => console.log(error));
    }

    return (
        <>
            {!isAuthenticated ? 
                <li onClick={login} className="menu-item nav-item">
                    <a role="button" tabIndex={0} className="nav-link">                        
                        <span>{t('navigationBar:login')}</span>
                    </a>
                </li>
                : 
                <li onClick={logout} className="menu-item nav-item">
                    <a role="button" tabIndex={0} className="nav-link">
                        <span>{t('navigationBar:logout')}</span>
                    </a>
                </li>
            }
        </>
    )
}

export default NavLogin