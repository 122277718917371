import { Company } from "@/company/model/company";
import { createContext } from "react";

export interface ICompanyState {
    company: Company;
    tempChanges: Company;
    editable: boolean;
    requestInProgress: boolean;
    updateEditable: (editable: boolean) => void;
    updateChanges: (company: Company) => void;
    updateCompany: (company: Company) => void;
    saveCompany: (company: Company) => Promise<Company>;
}

export const CompanyContext = createContext<ICompanyState>(undefined);