import { ConfigContext } from '@/context/config-context';
import useAuthenticatedPost from '@/hooks/useAuthenticatedPost';
import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { ExactGlobeDatabaseInfo } from "@/connectors/model/exactglobe/exactglobe-database-info";
import { Handler, useWizard, } from "@/components/wizard";
import { ConnectorExactContext } from '@/connectors/components/connector-exact/context/connector-exact-context';
import { CopyBlock } from 'react-code-blocks';
import { a11yDark } from 'react-code-blocks'

function InstallInfo() {

    const { handleStep } = useWizard();
    const connectorExactContext = useContext(ConnectorExactContext);
    const configContext = useContext(ConfigContext);
    const [installInfo, setInstallInfo] = useState(undefined);
    const formRef = createRef<HTMLFormElement>();

    const { data, isLoading, error, mutate } = useSWR(configContext.configBody.api.endpoints.connectors.setup.replace('{name}', 'exactglobe').replace('{step}', 'installinfo'), { shouldRetryOnError: true });

    useEffect(() => {
        if (data) {
            setInstallInfo(data);
        }
    }, [data]);

    useEffect(() => {
        document.body.classList.add('no-padding');
        return () => {
            document.body.classList.remove('no-padding');
        };
    }, []);

    return (
        <>
            <Row className='position-relative pt-5'>
                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <h2>
                        {t('connectors:exactglobe:add:description')}
                    </h2>
                </Col>
            </Row>
            <Row className='position-relative pt-3'>
                <Col sm={12} md={12} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 5, offset: 1 }}>
                    {installInfo &&
                        <>
                            <div>{t('connectors:exactglobe:add:copyCode')}</div>
                            <CopyBlock
                                text={installInfo.powershellCommand}
                                language="powershell"
                                theme={a11yDark}
                                showLineNumbers={false}
                                wrapLongLines={true}
                                codeBlock
                            />
                            <div>{t('connectors:exactglobe:add:executeCode')}</div>
                        </>
                    }
                </Col>
            </Row>
        </>
    )
}

export default InstallInfo