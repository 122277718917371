
import { ConfigContext } from "@/context/config-context";
import Header from "@/layout/header/header";
import { t, use } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import './buy-connectors-overview.scss'
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { Connector } from "./model/connector";
import ShopConnectorCard from "@/components/cards/shop-connector-card";

function BuyConnectorsOverview() {

    useDocumentTitle(t('documentTitles:functions'));

    var configContext = useContext(ConfigContext);
    const navigate = useNavigate();

    // Functions grid
    const [searchTerm, setSearchTerm] = useState('');
    const [activeFilter, setActiveFilter] = useState(true);
    const [inactiveFilter, setInactiveFilter] = useState(true);
    const [filteredFunctions, setFilteredFunctions] = useState<Connector[]>([]);

    const [functions, setFunctions] = useState<Connector[]>([]);
    const { data, isLoading, error } = useSWR(configContext.configBody.api.endpoints.connectors.list);
    
    useEffect(() => {

        if (!data) return;
        setFunctions(data);

    }, [data]);

    useEffect(() => {
        sortAndFilterFunctions();
    }, [functions, activeFilter, inactiveFilter, searchTerm]);

    const sortAndFilterFunctions = () => {

        let functionsResult = [...functions];

        if (searchTerm) {
            functionsResult = functionsResult.filter((employerFunction) =>
                employerFunction.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        functionsResult = functionsResult.sort((a, b) => { return new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime() });

        functionsResult = functionsResult.filter((employerFunction) => {
            if (activeFilter && inactiveFilter) {
                return true;
            } else if (activeFilter) {
                return employerFunction.enabled;
            } else if (inactiveFilter) {
                return !employerFunction.enabled;
            }
            return false;
        });

        setFilteredFunctions(functionsResult);
    }

    return (
        <div className="page-content bg-lichtgrijs">
            <div className="container py-5">
                <div className="row align-items-center bg-lichtgrijs pt-4 pt-sm-5 pb-2">
                    <div className="col-sm-12 col-lg-4">
                        <h2>{t('functions:overview:gridTitle')}</h2>
                    </div>

                    <div className="col-12 col-lg-8 mt-4 mt-lg-0 filters d-flex flex-column flex-md-row justify-content-md-end align-items-center">

                        <div className="form-top d-flex flex-row px-2">
                            <div className="form-check mr-4 mb-4 mb-md-0">
                                <input className="form-check-input" type="radio" name="selectProduct" id="Copilot" />
                                <label className="form-check-label" ><img src="/assets/img/sparse-icon-copilot.png" alt="Logo Copilot" width="20" height="20" className="img-fluid mr-2" />Copilot</label>
                            </div>

                            <div className="form-check mr-4 mr-md-5 mb-4 mb-md-0">
                                <input className="form-check-input" type="radio" name="selectProduct" id="PowerPlatform" />
                                <label className="form-check-label" ><img src="/assets/img/sparse-icon-power-platform.png" alt="Logo Power Platform" width="20" height="20" className="img-fluid mr-2" />Power Platform</label>
                            </div>
                        </div>
                        <div className='filter-toggle'>
                            <button
                                onClick={() => { setActiveFilter(!activeFilter) }}
                                className={`${activeFilter ? 'selected' : ''}`}>
                                {t('functions:overview:toggleActive')}
                            </button>
                        </div>
                        <div className='filter-toggle'>
                            <button
                                onClick={() => { setInactiveFilter(!inactiveFilter) }}
                                className={`${inactiveFilter ? 'selected' : ''}`}>
                                {t('functions:overview:toggleInactive')}
                            </button>
                        </div>

                        <div className="form-bottom">
                            <div className="form-search position-relative">
                                <Form.Control type="text" placeholder={t('functions:overview:placeholderGridSearch')} value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row mt-4">
                    {isLoading && <div className="loader"></div>}
                    {!isLoading && (
                        <>
                            {
                                filteredFunctions.map((employerFunction, index) => (
                                    <ShopConnectorCard customerConnector={employerFunction} key={index} />
                                ))
                            }
                        </>
                    )}
                   
                </div>

                <div className="row row-pagination pb-0 pb-lg-5">
                    <div className="col-12 text-center">
                        <a href="#" className="btn btn-pagination btn-prev"><span className="material-symbols-sharp">chevron_left</span></a>
                        <a href="#" className="btn btn-pagination btn-next"><span className="material-symbols-sharp">chevron_right</span></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyConnectorsOverview