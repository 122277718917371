import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import './connector-exactglobe-component.scss';
import PartnerFooter from "@/components/footers/partner-footer";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import Header from "@/layout/header/header";
import { Wizard, useWizard } from '@/components/wizard';
import Footer from "./general/footer";
import ConnectorExactProvider from '@/connectors/components/connector-exact/context/connector-exact-provider';
import DatabaseInfo from "./steps/database-info";
import InstallInfo from './steps/install-info';
import CreateConnector from './steps/create-connector';
import CheckConnection from './steps/check-connection';

function ConnectorExactGlobeComponent() {

    useDocumentTitle(t('documentTitles:connectorAdd'));

    useEffect(() => {
        document.body.classList.add('no-padding');

        return () => {
            document.body.classList.remove('no-padding');
        };
    }, []);


    return (
        <div className="page-content bg-lichtgrijs pb-5 exact-connector">
            
            <Container fluid className='add-container position-relative'>
                <Container>
                <h1 className='mt-5 mb-3'>{t('connectors:exactglobe:add:title')}</h1>
                                        
                    <ConnectorExactProvider>
                    <Wizard
                        startIndex={0}
                        // header={<Header />}
                        footer={<Footer />}
                    // wrapper={<Wrapper />}
                    >
                        <DatabaseInfo />
                        <CreateConnector/>
                        <InstallInfo/>
                        <CheckConnection/>
                    </Wizard>

                    </ConnectorExactProvider>
                </Container>
            </Container>

            <PartnerFooter />
        </div>
    )
}

export default ConnectorExactGlobeComponent