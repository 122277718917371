import * as React from 'react';

import { WizardValues } from './types';
import WizardContext from './wizardContext';

const useWizard = () => {
  const context = React.useContext(WizardContext);

  return context as WizardValues;
};

export default useWizard;   