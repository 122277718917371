import NavigationBlocker from "@/components/navigation-blocker/navigation-blocker";
import RadialChart from "@/components/radial-chart/radial-chart";
import { CompanyContext } from "@/context/company-context";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import useProfileCompletion from "@/hooks/useProfileCompletion";
import Header from "@/layout/header/header";
import { t } from "i18next";
import { createRef, useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Company } from "../model/company";
import CompanyDetails from "./company-cards/company-details";
import CompanyStats from "./company-cards/company-stats";
import './company-edit.scss';
import CompanyLogo from "./company-logo/company-logo";
import BetaMessageModal from "@/components/beta-message-modal/beta-message-modal";
import FormCard from "@/components/cards/form-card";

function CompanyEdit() {

    useDocumentTitle(t('documentTitles:companyEdit'));

    const navigate = useNavigate();
    const location = useLocation();
    const [validated, setValidated] = useState(false);
    const context = useContext(CompanyContext);
    const formRef = createRef<HTMLFormElement>();
    const editMode = location.state?.edit;
    const { profileCompletion } = useProfileCompletion();

    useEffect(() => {
        if (!context.company && !context.requestInProgress) {
            navigate(`/profile/detail/add`);
        }
    }, [context]);


    useEffect(() => {
        if (editMode) {
            context.updateEditable(true);
        } else {
            context.updateEditable(false);
        }
    }, []);

    const updateTempChanges = (propertyName, newValue) => {

        const updatedCompany: Company = {
            ...context.tempChanges,
            [propertyName]: newValue
        };

        context.updateChanges(updatedCompany);
    };

    const saveChanges = () => {

        setValidated(true);

        if (formRef.current?.checkValidity()) {

            context.saveCompany(context.tempChanges)
                .catch(() => { });

        } else {
            toast.error(t('general:invalidFields'));
        }
    };

    return (
        <div className="company-overview">


            <Container fluid className='bg-oranje position-relative pb-5'>
                <Container>

                    <div className='header-img full position-absolute px-0'>
                        <img src={'/assets/img/PowerConnector_3D_Visual_Profiel_3.jpg'} style={{ objectPosition: 'center center' }} alt='' />
                    </div>

                    <div className="position-relative pb-5">
                        <Header />
                    </div>

                    <Row className='position-relative pb-5 first-row-after-heading-img'>
                        <Col sm={12} md={12} lg={10} xl={10} xxl={10} className='d-flex flex-column text-wit'>
                            <p className='flex-grow-1'>
                                {t('company:edit:description')}
                            </p>
                        </Col>
                    </Row>

                    <Row className='position-relative mb-4'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='d-flex flex-column text-wit'>
                            <Form.Group as={Row}>
                                <Col>
                                    {context.editable ?
                                        <>
                                            <Form.Label>{t('company:details:labelName')}</Form.Label>
                                            <Form.Control type="text" placeholder={t('company:details:placeholderName')} value={context.tempChanges?.name ?? ''} onChange={e => updateTempChanges('name', e.target.value)} />
                                        </>
                                        : <div className="font-heavy h2 text-break">{context.company?.name}</div>
                                    }
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={{ span: 3, offset: 3 }} xl={{ span: 3, offset: 3 }} xxl={{ span: 3, offset: 3 }} className="mt-3 mt-lg-0">
                            <div className="d-flex h-100 flex-column align-items-center">
                                {/* <CompanyLogo editable={context.editable} width='240px' height='240px' companyId={context.company?.id} /> */}
                            </div>
                        </Col>
                    </Row>

                    <Row className='position-relative'>
                        <Col className="d-flex d-md-block justify-content-center">
                            <ButtonGroup>
                                {!context.editable &&
                                    <Button variant="wit" onClick={() => context.updateEditable(true)}>
                                        <i className="fas fa-pen me-2"></i>
                                        <span>{t('company:edit:editCompany')}</span>
                                    </Button>
                                }

                                {context.editable &&
                                    <>
                                        <Button variant="zwartblauw" disabled={context.requestInProgress} onClick={() => context.updateEditable(false)}>
                                            <i className="fas fa-xmark me-2"></i>
                                            <span>{t('general:cancel')}</span>
                                        </Button>
                                        <Button variant="zwartblauw" disabled={context.requestInProgress} onClick={saveChanges}>
                                            <i className="fas fa-floppy-disk me-2"></i>
                                            <span>{t('company:edit:saveCompany')}</span>
                                        </Button>
                                    </>
                                }
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Form ref={formRef} validated={validated} noValidate>

                <Container fluid className='bg-blauw py-5'>
                    <Container>
                        <Row className="pt-4">
                            <Col sm={12} md={12} lg={8} xl={8} xxl={8}>
                            <FormCard title={t('company:contactDetails:title')} className="detail-card" >                              
                                <Form.Group as={Row} className="mt-4">
                                <Col>
                                    <Form.Label className={`${!context.editable && 'no-edit font-bold'}`}>{t('company:details:labelCompanyName')}</Form.Label>
                                    {context.editable ?
                                        <Form.Control type="text" placeholder={t('company:details:placeholderCompanyName')} value={context.tempChanges?.companyName ?? ''} onChange={e => updateTempChanges('companyName', e.target.value)} />
                                        : <p className='flex-grow-1'>{context.company?.companyName}</p>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-2">
                                <Col>
                                    <Form.Label className={`${!context.editable && 'no-edit font-bold'}`}>{t('company:details:labelPhone')}</Form.Label>
                                    {context.editable ?
                                        <Form.Control type="text" placeholder={t('company:details:placeholderPhone')} value={context.tempChanges?.phone ?? ''} onChange={e => updateTempChanges('phone', e.target.value)} />
                                        : <p className='flex-grow-1'>{context.company?.phone}</p>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-2">
                                <Col>
                                   
                                    {context.editable ?
                                        <>
                                        <Form.Label className={`${!context.editable && 'no-edit font-bold'}`}>{t('company:details:labelStreet')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('company:details:placeholderStreet')} value={context.tempChanges?.street ?? ''} onChange={e => updateTempChanges('street', e.target.value)} />
                                        </> : 
                                        <><Form.Label className={`${!context.editable && 'no-edit font-bold'}`}>{t('company:details:labelAddress')}</Form.Label>
                                        <p className='flex-grow-1'>{context.company?.street} {context.company?.houseNumber}</p>
                                        </>
                                    }
                                </Col>
                                <Col sm={4} md={4} lg={3} xl={3} xxl={3}>                                    
                                    {context.editable ? 
                                        <>
                                        <Form.Label className={`${!context.editable && 'no-edit font-bold'}`}>{t('company:details:labelHouseNumber')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('company:details:placeholderHouseNumber')} value={context.tempChanges?.houseNumber ?? ''} onChange={e => updateTempChanges('houseNumber', e.target.value)} />
                                        </> : <p className='flex-grow-1'></p>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-0">
                                <Col sm={4} md={4} lg={3} xl={3} xxl={3}>
                                    
                                    {context.editable ? 
                                        <>
                                            <Form.Label className={`${!context.editable && 'no-edit font-bold'}`}>{t('company:details:labelPostalcode')}</Form.Label>
                                            <Form.Control type="text" placeholder={t('company:details:placeholderPostalcode')} value={context.tempChanges?.postalCode ?? ''} onChange={e => updateTempChanges('postalCode', e.target.value)} />
                                        </> : <p className='flex-grow-1'><span>{context.company?.postalCode}</span> <span>{context.company?.city}</span></p>
                                    }
                                </Col>
                                <Col>
                                    {context.editable ? <>
                                        <Form.Label className={`${!context.editable && 'no-edit font-bold'}`}>{t('company:details:labelCity')}</Form.Label>
                                        <Form.Control type="text" placeholder={t('company:details:labelCity')} value={context.tempChanges?.city ?? ''} onChange={e => updateTempChanges('city', e.target.value)} />
                                        </> : <p className='flex-grow-1'></p>
                                    }
                                </Col>
                            </Form.Group>
                            </FormCard>
                            </Col>
                            <Col sm={12} md={12} lg={6} xl={4} xxl={4}>
                            </Col>
                            <Col sm={12} md={12} lg={6} xl={4} xxl={4}>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className='bg-paars py-5'>
                    <Container>
                        <Row className="pb-5">
                            
                        </Row>
                    </Container>
                </Container>
            </Form>

            <NavigationBlocker shouldBlock={{ obj1: context.company, obj2: context.tempChanges }} />
        </div>
    )
}

export default CompanyEdit;
