import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import './connector-teamleaderfocus-component.scss';
import PartnerFooter from "@/components/footers/partner-footer";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import Header from "@/layout/header/header";
import { Wizard, useWizard } from '@/components/wizard';
import Footer from "./general/footer";
import ConnectorTeamLeaderFocusEdit from './general/edit';
import TeamLeaderSettingsStep from './steps/teamleader-settings-step';
import ShowApiKeyStep from './steps/show-apikey-step';
import AuthorizeStep from './steps/authorize-step';
import ConnectoreTeamleaderFocusProvider from './context/connector-teamleaderfocus-provider';

interface ConnectorTeamLeaderFocusComponentProps {
    isEditMode: boolean;
  }

function ConnectorTeamLeaderFocusComponent({ isEditMode }: ConnectorTeamLeaderFocusComponentProps) {

    useDocumentTitle(t('documentTitles:connectorAdd'));

    useEffect(() => {
        document.body.classList.add('no-padding');

        return () => {
            document.body.classList.remove('no-padding');
        };
    }, []);


    return (
        <div className="teamleaderfocus-connector">

            <Container fluid className='add-container position-relative'>
                <Container>
                    <div className='header-img full position-absolute px-0'>
                        <img src={'/assets/img/WIHV_3D_Visual_Leerpad.jpg'} alt='' />
                    </div>

                    <div className="position-relative">
                        <Header />
                    </div>
                    <ConnectoreTeamleaderFocusProvider>
                        {isEditMode &&
                                <ConnectorTeamLeaderFocusEdit/>
                        }

                        {!isEditMode &&
                                <Wizard
                                    startIndex={0}
                                    // header={<Header />}
                                    footer={<Footer />}
                                // wrapper={<Wrapper />}
                                >
                                    <TeamLeaderSettingsStep />
                                    <AuthorizeStep />
                                    <ShowApiKeyStep />
                                </Wizard>
                        }

                    </ConnectoreTeamleaderFocusProvider>
                </Container>
            </Container>

            <PartnerFooter />
        </div>
    )
}

export default ConnectorTeamLeaderFocusComponent