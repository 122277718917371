import { Col, Container, Row } from "react-bootstrap"
import PublicNavigationBar from "../public-navigation-bar/public-navigation-bar"
import './page-header.scss'
import React from "react";

type PageHeaderProps = {
    title?: string
    imgSrc?: string
    description: React.ReactNode;
    link?: string
    linkText?: string
    colWidth?: number
    paddingHeader?: boolean
}

function PageHeader({ title, imgSrc, description, link, linkText, colWidth = 5, paddingHeader = true }: PageHeaderProps) {
    return (
        <>
            <PublicNavigationBar />
            <Container fluid className={`position-relative bg-roze page-header`}>
                <Container className={`position-relative ${paddingHeader ? 'pb-5' : ''}`}>

                    {
                        imgSrc &&
                        <div className='page-header-img position-absolute px-0'>
                            <img src={imgSrc} className="img-fluid" alt='' />
                        </div>
                    }

                    <Row className="pb-5 first-content">
                        <Col sm={12} md={12} lg={colWidth} xl={colWidth}>
                            <h1 className="pe-5 mb-4 font-black">{title}</h1>
                            <p className="pe-5 mb-4 font-light">{description}</p>
                            {
                                link && linkText &&
                                <a href={link} className="btn btn-secondary">{linkText}
                                    <i className="fas fa-chevron-right ms-2" />
                                </a>
                            }
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default PageHeader