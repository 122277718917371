import { createContext } from "react";
import { Settings } from "../modules/settings/model/settings";

export interface ISettingsState {
    settings: Settings;
    tempChanges: Settings;
    editable: boolean;
    requestInProgress: boolean;
    updateEditable: (editable: boolean) => void;
    updateChanges: (settings: Settings) => void;
    updateSettings: (settings: Settings) => void;
    saveSettings: (settings: Settings) => void;
}

export const SettingsContext = createContext<ISettingsState>(undefined);