import FormCard from '@/components/cards/form-card';
import { FunctionContext } from '@/context/function-context';
import { useWorkLocationTranslation } from '@/general/i18n/translation-helpers';
import { useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmployerFunction } from '../model/employer-function';
import { WorkLocation } from '../model/work-location';
import './function-details-card.scss';

function FunctionAdditionalInfo() {

    const { t } = useTranslation();
    const { translateWorkLocation } = useWorkLocationTranslation();
    const context = useContext(FunctionContext);
    const workLocations = Object.values(WorkLocation).filter(value => !isNaN(Number(value)));

    const updateTempChanges = (propertyName, newValue) => {

        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            [propertyName]: newValue
        };

        context.updateChanges(updatedFunction);
    };

    return (
        <FormCard title={t('functions:detail:additionalInfo:title')}>
            <Form.Group as={Row} className="">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:additionalInfo:labelNumberOfOpenPositions')}</Form.Label>
                    {context.editable ?
                        <Form.Control type="number" min={0} placeholder="" value={context.tempChanges?.numberOfOpenPositions || ''} onChange={e => updateTempChanges('numberOfOpenPositions', e.target.value)} />
                        : <div>{context.employerFunction?.numberOfOpenPositions}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:additionalInfo:labelWorkLocation')}</Form.Label>
                    {context.editable ?
                        <Form.Select
                            aria-label={t('functions:detail:additionalInfo:placeholderWorkLocation')}
                            value={context.tempChanges?.workLocation || 0}
                            onChange={(e) => {
                                if (e.target.selectedIndex == 0) { return; }
                                updateTempChanges('workLocation', e.target.value)
                            }}
                        >
                            <option key={-1} value="">{t('functions:detail:additionalInfo:placeholderWorkLocation')}</option>
                            {workLocations.map((level, index) => (
                                <option key={index} value={level}>
                                    {translateWorkLocation(+level)}
                                </option>
                            ))}
                        </Form.Select>
                        : <div>{translateWorkLocation(context.employerFunction?.workLocation)}</div>
                    }
                </Col>
            </Form.Group>
        </FormCard>
    );
}

export default FunctionAdditionalInfo;