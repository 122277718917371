export enum EducationLevel {
    MBO1 = 1,
    MBO2 = 2,
    MBO3 = 3,
    MBO4 = 4,
    MBOPlus = 5,
    AD = 6,
    Bachelor = 7,
    Master = 8,
    Doctorate = 9,
}