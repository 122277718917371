import { Knowledge } from "./knowledge";
import { Skills } from "./skills";

export interface FunctionDataSource {
    id: string;
    type: DataSourceType;
    supplier?: DataSourceSupplier;
    label: string;
    skills: Skills;
    knowledge: Knowledge;
}

export enum DataSourceType {
    Profession,
    Vacancy,
    Feedback,
    Employer
}

export enum DataSourceSupplier {
    Esco,
    LinkedIn,
    Wihv
}