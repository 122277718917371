import { ConfigContext } from '@/context/config-context';
import debounce from "debounce-promise";
import { useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { SkillSearchDocument } from './skill-search-document';
import { useTranslation } from "react-i18next";

type SearchBarProps = {
    type: 'skill' | 'knowledge';
    onSkillSelected: (s: any) => void;
    onSkillCreated: (s: string) => void;
}

export default function SkillsSearchBar({ type, onSkillSelected, onSkillCreated }: SearchBarProps) {

    var configContext = useContext(ConfigContext);
    const { t } = useTranslation();

    const handleSelectedOption = (selectedOption: any) => {
        if (selectedOption) {
            const selectedSkill = selectedOption.value as SkillSearchDocument;
            onSkillSelected({ name: selectedSkill.name, id: selectedSkill.id, description: selectedSkill.description });
        }
    }

    const handleCreatedOption = (createdOption: string) => {
        if (createdOption) {
            onSkillCreated(createdOption);
        }
    }

    const _loadProfessionSuggestions = (inputValue: string) => {
        const savedLanguage = localStorage.getItem('selectedLanguage') ?? 'en';
        const skillType = type === 'skill' ? 'http://data.europa.eu/esco/skill-type/skill' : 'http://data.europa.eu/esco/skill-type/knowledge';

        const body = {
            filter: `language eq '${savedLanguage}' and type eq '${skillType}'`,
            select: 'name, id, description',
            search: inputValue,
            suggesterName: 'esco-skill-suggester'
        };

        return fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.search.skills}/suggest`, {
            credentials: "same-origin",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .then((result) => {
                const transformedArray = result.value.map((item: SkillSearchDocument) => {
                    return {
                        value: item,
                        label: item.name
                    };
                });
                return transformedArray;
            })
            .catch(
                (error) => {

                });
    }

    const loadProfessionSuggestions = debounce(_loadProfessionSuggestions, 300);

    return (
        <Form.Group as={Row}>
            <Col sm={5}>
                <AsyncCreatableSelect
                    placeholder={type === 'skill' ? t('functions:detail:skills:skillsSearchPlaceholder') : t('functions:detail:skills:knowledgeSearchPlaceholder')}
                    onChange={(s) => handleSelectedOption(s)}
                    onCreateOption={(s) => handleCreatedOption(s)}
                    formatCreateLabel={(inputValue) => <span role='button'>{t('general:create')}: "<span className='font-bold'>{inputValue}</span>"</span>}
                    defaultOptions={false}
                    controlShouldRenderValue={false}
                    loadOptions={loadProfessionSuggestions}
                    noOptionsMessage={(e) => e.inputValue ? type === 'skill' ? t('functions:detail:skills:skillsSearchNoResults') : t('functions:detail:skills:knowledgeSearchNoResults') : null}
                    required={true}
                    className='p-0'
                    classNames={{
                        menuList: () => 'no-scrollbar'
                    }}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            minWidth: '260px',
                            borderRadius: '26px',
                            padding: '0 0.5rem',
                            boxShadow: 'none'
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            cursor: 'pointer'
                        }),
                    }} />
            </Col>
        </Form.Group>
    );
};