import { ConfigContext } from "@/context/config-context";
import { FunctionDataSource } from "@/connectors/model/function-data-source";
import useAuthenticatedPost from "@/hooks/useAuthenticatedPost";
import { t } from "i18next";
import { createRef, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import useSWR from "swr";
import './vacancy-upload.scss';

type VacancyUploadProps = {
    employerfunctionVacancyDescription?: string
    employerfunctionVacancyDataSourceId?: string
    vacancyDescriptionChanged: (vacancyDescription: string) => void
    dataSourceRetrieved: (dataSource: FunctionDataSource) => void
}

enum VacancyUploadStatus {
    NotStarted = 0,
    Processing = 1,
    Completed = 2,
    Failed = 3
}

function VacancyUpload({ employerfunctionVacancyDescription, employerfunctionVacancyDataSourceId, dataSourceRetrieved, vacancyDescriptionChanged }: VacancyUploadProps) {

    const configContext = useContext(ConfigContext);
    const { postRequest } = useAuthenticatedPost();

    const [status, setStatus] = useState<VacancyUploadStatus>(VacancyUploadStatus.NotStarted);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [vacancyDescription, setVacancyDescription] = useState<string>('');
    const [vacancyDataSource, setVacancyDataSource] = useState<FunctionDataSource>(undefined);

    const formRef = createRef<HTMLFormElement>();
    const [validated, setValidated] = useState(false);

    const { data: apiVacancyDataSource, isLoading } = useSWR<FunctionDataSource>(employerfunctionVacancyDataSourceId ? configContext.configBody.api.endpoints.vacancies.detail.replace('{id}', employerfunctionVacancyDataSourceId) : null);

    useEffect(() => {
        if (!employerfunctionVacancyDescription) { return; }
        setVacancyDescription(employerfunctionVacancyDescription);
    }, [employerfunctionVacancyDescription]);

    useEffect(() => {
        if (!apiVacancyDataSource) { return; }
        setVacancyDataSource(apiVacancyDataSource);
    }, [apiVacancyDataSource]);

    useEffect(() => {
        if (!vacancyDescription) { return; }
        vacancyDescriptionChanged(vacancyDescription);
    }, [vacancyDescription]);

    useEffect(() => {
        if (!vacancyDataSource) { return; }
        setStatus(VacancyUploadStatus.Completed);
        dataSourceRetrieved(vacancyDataSource);
    }, [vacancyDataSource]);

    const processVacancyDescription = () => {

        setValidated(true);
        if (formRef.current?.checkValidity()) {

            setStatus(VacancyUploadStatus.Processing);

            postRequest(configContext.configBody.api.endpoints.vacancies.list, { description: vacancyDescription })
                .then((response: FunctionDataSource) => {
                    setVacancyDataSource(response);
                    toast.success(t('functions:detail:vacancy:statusCompleted'));
                })
                .catch((error) => {
                    console.error(error);
                    setStatus(VacancyUploadStatus.Failed);
                    toast.error(t('functions:detail:vacancy:statusFailed'));
                });
        }
    }

    return (
        <>
            <div className="vacancy-upload my-4">
                <div className="vacancy-upload__label d-flex flex-column align-items-start gap-1 mb-2 ms-2">
                    <span>
                        <span className="vacancy-upload__logo me-2">AI</span>
                        <span className="vacancy-upload__suffix">({t('general:optional')})</span>
                    </span>
                    <span className="vacancy-upload__title">{t('functions:detail:vacancy:title')}</span>
                </div>

                <div className="vacancy-upload__status d-flex p-2 align-items-center">
                    <button className="btn btn-primary-outline" onClick={() => { setValidated(false); setShowModal(true); }}>
                        {
                            employerfunctionVacancyDataSourceId
                                ? t('functions:detail:vacancy:vacancyReuploadBtn')
                                : t('functions:detail:vacancy:vacancyUploadBtn')
                        }
                    </button>

                    {status === VacancyUploadStatus.Processing &&
                        <div className="ms-3">
                            {t('functions:detail:vacancy:statusProcessing')}
                            <i className="fas fa-spinner fa-spin me-2"></i>
                        </div>
                    }

                    {status === VacancyUploadStatus.Completed &&
                        <div className="ms-3">
                            <i className="fas fa-check-circle text-success me-2"></i>
                            {t('functions:detail:vacancy:statusCompleted')}
                        </div>
                    }

                    {status === VacancyUploadStatus.Failed &&
                        <div className="ms-3">
                            <i className="fas fa-exclamation-circle text-danger me-2"></i>
                            {t('functions:detail:vacancy:statusFailed')}
                        </div>
                    }
                </div>
            </div>

            <Modal className="vacancy-upload wihv-modal" show={showModal} onHide={() => setShowModal(false)} backdrop="static" centered>
                <Modal.Header>
                    <div className='h3 text-donkerblauw'>{t('functions:detail:vacancy:modalTitle')}</div>
                </Modal.Header>

                <Modal.Body>
                    <Form ref={formRef} validated={validated} noValidate>

                        <p>{t('functions:detail:vacancy:description')}</p>

                        <Form.Group as={Row} className="mt-4 mb-4">
                            <Col>
                                <Form.Label>{t('functions:detail:vacancy:labelVacancyDescription')}</Form.Label>
                                <Form.Control as="textarea" rows={15} maxLength={5000} placeholder={t('functions:detail:vacancy:placeholderVacancyDescription')} value={vacancyDescription ?? ''} onChange={e => setVacancyDescription(e.target.value)} />
                                <p className="pt-2 pe-2 text-end text-muted">{(vacancyDescription ?? '').length} / 5000</p>
                            </Col>
                        </Form.Group>

                        {status === VacancyUploadStatus.Processing &&
                            <div className="vacancy-upload__alert alert-info">
                                {t('functions:detail:vacancy:statusProcessing')}
                                <i className="fas fa-spinner fa-spin ms-3"></i>
                            </div>
                        }

                        {status === VacancyUploadStatus.Completed &&
                            <div className="vacancy-upload__alert alert-success">
                                <i className="fas fa-check-circle text-success me-2"></i>
                                {t('functions:detail:vacancy:statusCompleted')}
                            </div>
                        }

                        {status === VacancyUploadStatus.Failed &&
                            <div className="vacancy-upload__alert alert-danger">
                                <i className="fas fa-exclamation-circle text-danger me-2"></i>
                                {t('functions:detail:vacancy:statusFailed')}
                            </div>
                        }

                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary mt-2"
                                disabled={!vacancyDescription || status === VacancyUploadStatus.Processing || isLoading}
                                onClick={() => processVacancyDescription()}>
                                    {
                                        employerfunctionVacancyDataSourceId
                                            ? t('functions:detail:vacancy:vacancyRegenerateBtn')
                                            : t('functions:detail:vacancy:vacancyGenerateBtn')
                                    }
                                <i className="fas fa-file-arrow-up fa-xl ms-2"></i>
                            </button>
                        </div>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary-outline transparent" onClick={() => setShowModal(false)}>
                        {t('general:close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default VacancyUpload