import { ConfigContext } from '@/context/config-context';
import getToken from '@/general/token-retriever';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";

const useAuthenticatedPut = () => {
    const navigate = useNavigate();
    const msalContext = useMsal();
    var configContext = useContext(ConfigContext);
    const { t } = useTranslation();

    const putRequest = async (url: string, data: any) => {

        const token = await getToken(msalContext, configContext);
        const savedLanguage = localStorage.getItem('selectedLanguage');

        const response = await axios.put(configContext.configBody.api.baseUrl + url, data, {
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Accept-Language': savedLanguage,
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey,
            }
        });

        if (response.status === 401) {
            toast.error(t('unauthorizedAccess'));
            return navigate("/not-authorized");
        }

        if (!response.data) {
            // do something with other errors?
            console.error(response);
            throw Error(response.statusText);
        }

        return response.data;
    };

    return putRequest;
};

export default useAuthenticatedPut;