import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import './beta-message-modal.scss';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

type BetaMessageModalProps = {
    show?: boolean;
    onClose?: () => void;
}

function BetaMessageModal({ show = false, onClose = () => {} }: BetaMessageModalProps) {
    const [showBetaMessage, setShowBetaMessage] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem('betaMessageShown')) {
            setShowBetaMessage(true);
            localStorage.setItem('betaMessageShown', 'true');
        }
    }, []);

    useEffect(() => {
        if (show) {
            setShowBetaMessage(true);
        }
    }, [show]);

    const handleClose = () => { setShowBetaMessage(false); onClose(); }

    return (
        <Modal className='wihv-modal beta-message' show={showBetaMessage} backdrop='static' centered onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className='font-heavy text-paars'>{t('general:betaMessage:title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {t('general:betaMessage:description1')}
                    <Link to="/contact">{t('general:betaMessage:contactLink')}</Link>
                </p>
                <p>{t('general:betaMessage:description2')}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-secondary' onClick={(handleClose)}>
                    <i className='fas fa-check fa-lg me-2' />
                    {t('general:betaMessage:confirmBtn')}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default BetaMessageModal;