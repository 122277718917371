import ScrollToTopNavigation from "@/components/scroll-to-top/scroll-to-top-navigation";
import useIdleDetection from "@/hooks/useIdleDetection";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import NavigationBar from "./navigation-bar/navigation-bar";
import './platform-layout.scss';
import ScrollToTopButton from "@/components/scroll-to-top/scroll-to-top-button";

function PlatformLayout() {

    useIdleDetection();

    return (
        <div className="app-container">

            <ScrollToTopButton />
            <ScrollToTopNavigation />
            <NavigationBar />

            <main>
                <Outlet />
            </main>

            <Toaster
                position="bottom-center"
                gutter={8}
                toastOptions={
                    {
                        duration: 5000,
                        success: {
                            duration: 3000,
                        },
                    }
                }
            />
        </div>
    );
}

export default PlatformLayout;