import { useWizard } from "@/components/wizard";
import './footer.scss';
import { t } from 'i18next';
import { useContext } from "react";
import { ConnectorExactContext } from "@/connectors/components/connector-exact/context/connector-exact-context";

export default function Footer() {

    const connectorExactContext = useContext(ConnectorExactContext);
    const { handleStep, previousStep, nextStep, activeStep, isLastStep, isFirstStep } = useWizard();
    const getStepClassName = (step: number) => {
        return `voortgang-item d-flex flex-column align-items-center ${step <= activeStep ? 'current' : ''}`
    }

    const getProgress = (step: number) => {
        return <span
            className="voortgang-status">{step < activeStep ? <span className="icon-check">{step}</span> : step}</span>
    }

    return (

        <div className="footer container-fluid fixed-bottom">
            <div className="row bg-zwartblauw">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-2 text-end text-md-right">
                            {!isFirstStep && <a onClick={() => previousStep()}
                                className={`btn btn-oranje btn-wizard`}><span className="icon-arrow-left"></span>{t('connectors:exactglobe:add:previous')}</a>}
                        </div>
                        <div className="col-sm-12 col-md-8 py-3">
                            <div className="voortgang-bar d-flex justify-content-center">

                                <div className={getStepClassName(0)}>  {getProgress(1)}<span
                                    className="voortgang-omschrijving">{t('connectors:exactglobe:add:stepDatabase')}</span> </div>
                                <div className={getStepClassName(1)}> {getProgress(2)} <span
                                    className="voortgang-omschrijving">{t('connectors:exactglobe:add:stepCloudInit')}</span> </div>
                                <div className={getStepClassName(2)}> {getProgress(3)} <span
                                    className="voortgang-omschrijving">{t('connectors:exactglobe:add:stepServerInstallation')}</span> </div>
                                <div className={getStepClassName(3)}> {getProgress(4)} <span className="voortgang-omschrijving">{t('connectors:exactglobe:add:stepCheckConnection')}</span> </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 text-begin text-md-right">
                            {!isLastStep &&  <a onClick={() => nextStep()}
                                className={`btn btn-oranje btn-wizard`}>{t('connectors:exactglobe:add:next')}<span className="icon-arrow-right"></span></a>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}