import { PublicClientApplication } from '@azure/msal-browser';
import i18n from "i18next";
import ReactDOM from 'react-dom/client';
import { I18nextProvider, initReactI18next } from "react-i18next";
import App from './app';
import { IConfigContext } from './context/config-context';
import { ConfigProvider } from './context/config-provider';
import { createApplicationInsight } from './general/application-insight';
import { getConfig } from './general/config-retriever';
import ErrorBoundary from './general/error-boundary';
import { getTranslations } from './general/i18n/translation-retriever';
import { createMsal } from './general/msal';
import './index.scss';
import reportWebVitals from './reportWebVitals';

getConfig().then(async configBody => {
    const config = {
        configLoaded: true,
        configBody: { ...configBody }
    } as IConfigContext;

    await getTranslations(config.configBody.api)
        .then(translations => {
            i18n.use(initReactI18next).init({
                lng: "en",
                fallbackLng: "en",
                interpolation: {
                    escapeValue: false,
                },
                resources: translations
            });
        });

    const ai = createApplicationInsight(config);
    const msalInstance = createMsal(config, ai);
    renderComponent(config, msalInstance);
});

function getContainer() {
    return document.getElementById('root');
}

function renderComponent(config: IConfigContext, msalInstance: PublicClientApplication) {
    let componentElement = getContainer();
    if (componentElement) {
        const root = ReactDOM.createRoot(componentElement);
        root.render(
            //<React.StrictMode>
                <ErrorBoundary>
                    <ConfigProvider Config={config}>
                        <I18nextProvider i18n={i18n}>
                            <App msalInstance={msalInstance} />
                        </I18nextProvider>
                    </ConfigProvider>
                </ErrorBoundary>
            //</React.StrictMode>
        );
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
