import { ErrorComponent } from "@/components/error-component/error-component";
import React from "react";

interface IErrorBoundaryState {
    hasError: boolean;
}

export default class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {

    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.error('Error!', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            console.log('Error!');
            
            return <ErrorComponent />
        }

        return this.props.children;
    }
}