import SkillsSelection from "@/components/skills-selection/skills-selection";
import { FunctionContext } from "@/context/function-context";
import { useContext, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FunctionDataSource } from "../model/function-data-source";
import { Knowledge } from "../model/knowledge";
import { Skills } from "../model/skills";

type FunctionSkillsProps = {
    sources: FunctionDataSource[];
}

function FunctionSkills({ sources }: FunctionSkillsProps) {

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const context = useContext(FunctionContext);
    const { t } = useTranslation();

    const handleOnSkillsChange = (skills: Skills) => {
        context.updateChanges({ ...context.tempChanges, skills: skills });
    }

    const handleOnKnowledgeChange = (knowledge: Knowledge) => {
        context.updateChanges({ ...context.tempChanges, knowledge: knowledge });
    }

    return (
        <>
            {context.editable ?
                <Row>
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <SkillsSelection
                            sources={sources ?? []}
                            skills={context.tempChanges?.skills ?? { essential: [], optional: [] }}
                            knowledge={context.tempChanges?.knowledge ?? { essential: [], optional: [] }}
                            onSkillsChange={handleOnSkillsChange}
                            onKnowledgeChange={handleOnKnowledgeChange} />
                    </Col>
                </Row>
                :
                <div className="skills-view-container bg-wit">
                    <Row className="position-relative text-donkerblauw px-4 pt-4 px-md-5 pt-md-5">
                        <div className="h3 font-bold m-0 text-break">{t('functions:detail:skills:title')}</div>
                    </Row>
                    <Row className="px-3 py-3 px-md-5 py-md-4">
                        <Col md={12} lg={6}>
                            <div className="skills-container pb-4">
                                <div className="header flex-lg-row">
                                    <div className="font-heavy p-0 m-0">{t('functions:detail:skills:skills')}</div>
                                </div>
                                <div className="skills-selection">
                                    <div className="sub-header text-donkerblauw font-medium-italic pt-2 m-0">{t('functions:detail:skills:essential')}</div>
                                    {context.employerFunction?.skills?.essential?.map((item, index) =>
                                        <div key={index} className="label">
                                            <div className="title">{index + 1}. {item.name}</div>
                                            {item.description && <div className='info ms-2' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: item.name, description: item.description }); setShowInfoModal(true); }}>
                                                <i className='fas fa-info fa-sm' />
                                            </div>}
                                        </div>
                                    )}
                                    {context.employerFunction?.skills?.essential?.length === 0 &&
                                        <div className="pb-4">{t('functions:detail:skills:noEssentialSkillsSelected')}</div>
                                    }
                                    <div className="sub-header text-donkerblauw font-medium-italic pt-2 m-0">{t('functions:detail:skills:optional')}</div>
                                    {context.employerFunction?.skills?.optional?.map((item, index) =>
                                        <div key={index} className="label">
                                            <div className="title">{index + 1}. {item.name}</div>
                                            {item.description && <div className='info ms-2' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: item.name, description: item.description }); setShowInfoModal(true); }}>
                                                <i className='fas fa-info fa-sm' />
                                            </div>}
                                        </div>
                                    )}
                                    {context.employerFunction?.skills?.optional?.length === 0 &&
                                        <div className="pb-4">{t('functions:detail:skills:noOptionalSkillsSelected')}</div>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="knowledge-container">
                                <div className="header flex-lg-row">
                                    <div className="font-heavy p-0 m-0">{t('functions:detail:skills:knowledge')}</div>
                                </div>
                                <div className="skills-selection">
                                    <div className="sub-header text-donkerblauw font-medium-italic pt-2 m-0">{t('functions:detail:skills:essential')}</div>
                                    {context.employerFunction?.knowledge?.essential?.map((item, index) =>
                                        <div key={index} className="label">
                                            <div className="title">{index + 1}. {item.name}</div>
                                            {item.description && <div className='info ms-2' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: item.name, description: item.description }); setShowInfoModal(true); }}>
                                                <i className='fas fa-info fa-sm' />
                                            </div>}
                                        </div>
                                    )}
                                    {context.employerFunction?.knowledge?.essential?.length === 0 &&
                                        <div className="pb-4">{t('functions:detail:skills:noEssentialKnowledgeSelected')}</div>
                                    }
                                    <div className="sub-header text-donkerblauw font-medium-italic pt-2 m-0">{t('functions:detail:skills:optional')}</div>
                                    {context.employerFunction?.knowledge?.optional?.map((item, index) =>
                                        <div key={index} className="label">
                                            <div className="title">{index + 1}. {item.name}</div>
                                            {item.description && <div className='info ms-2' role='button' tabIndex={0} onClick={(e) => { e.stopPropagation(); setSelectedValue({ name: item.name, description: item.description }); setShowInfoModal(true); }}>
                                                <i className='fas fa-info fa-sm' />
                                            </div>}
                                        </div>
                                    )}
                                    {context.employerFunction?.knowledge?.optional?.length === 0 &&
                                        <div className="pb-4">{t('functions:detail:skills:noOptionalKnowledgeSelected')}</div>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal className="wihv-modal" centered show={showInfoModal} onHide={() => setShowInfoModal(false)}>
                        <Modal.Header closeButton>
                            <div className='h3 text-donkerblauw'>{t('general:meaning')}</div>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='py-3 px-2'><span className='font-bold'>{selectedValue?.name}:</span> {selectedValue?.description}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary-outline" onClick={() => setShowInfoModal(false)}>{t('general:close')}</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </>
    )
}

export default FunctionSkills;