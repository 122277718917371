import { DataSourceSupplier, DataSourceType, FunctionDataSource } from "@/connectors/model/function-data-source";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Select from 'react-select';

type SkillSourceSelectionProps = {
    currentSource: FunctionDataSource;
    sources: FunctionDataSource[];
    onSourceChange: (source: FunctionDataSource) => void;
};

function SkillSourceSelection({ currentSource, sources, onSourceChange }: SkillSourceSelectionProps) {

    const [activeDataSourceTypeKey, setActiveDataSourceTypeKey] = useState<DataSourceType>(null);
    const [activeDataSourceSupplierKey, setActiveDataSourceSupplierKey] = useState<DataSourceSupplier>(null);

    useEffect(() => {
        setActiveDataSourceTypeKey(currentSource?.type);
        setActiveDataSourceSupplierKey(currentSource?.supplier);
    }, [currentSource]);

    function handleActiveDataSourceTypeChange(key: DataSourceType) {
        if (key !== activeDataSourceTypeKey) {
            setActiveDataSourceTypeKey(key);
            onSourceChange(sources?.find(x => x.type === key));
        }
    }

    function handleActiveDataSourceSupplierChange(key: DataSourceSupplier) {
        if (key !== activeDataSourceSupplierKey) {
            setActiveDataSourceSupplierKey(key);
            onSourceChange(sources?.find(x => x.supplier === key));
        }
    }

    return (
        <div>
            <Tabs
                activeKey={activeDataSourceTypeKey}
                onSelect={(k) => handleActiveDataSourceTypeChange(+k)}
                className="source-type-tabs"
                fill={true}>

                {sources?.filter(x => x.type == DataSourceType.Employer).length == 1 &&
                    <Tab
                        eventKey={DataSourceType.Employer}
                        className="employer-tab"
                        title={
                            <span className="d-flex align-items-center justify-content-center">
                                <img className="wihv me-2" src={'/assets/img/wihv-icon.png'} height={18} />
                                <span>{t('enums:dataSourceType:employer')}</span>
                            </span>
                        }>
                    </Tab>
                }

                <Tab
                    eventKey={DataSourceType.Profession}
                    disabled={sources?.filter(x => x.type == DataSourceType.Profession).length == 0}
                    className="profession-tab"
                    title={
                        <>
                            <i className="fas fa-user-tie me-2" />
                            <span>{t('enums:dataSourceType:profession')}</span>
                        </>
                    }>
                    {
                        <div className="profession-source-selection">
                            {
                                sources?.filter(x => x.type != DataSourceType.Vacancy)?.length > 1 &&
                                <Select
                                    className="source-select mb-3 mx-3"
                                    defaultValue={{ label: sources[0].label, value: sources[0] }}
                                    value={{ label: currentSource?.label, value: currentSource }}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isSearchable={false}
                                    options={sources.filter(x => x.type == DataSourceType.Profession).map((source) => { return { value: source, label: source.label } })}
                                    onChange={(selectedOption) => { onSourceChange(selectedOption.value) }}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderRadius: '25px',
                                            padding: '0rem 0.5rem',
                                            boxShadow: 'none'
                                        })
                                    }}
                                />
                            }

                            <Tabs
                                activeKey={activeDataSourceSupplierKey}
                                onSelect={(k) => handleActiveDataSourceSupplierChange(+k)}
                                className="source-supplier-tabs"
                                fill={true}>
                                <Tab
                                    disabled={sources?.filter(x => x.supplier == DataSourceSupplier.Esco).length == 0}
                                    eventKey={DataSourceSupplier.Esco}
                                    title={<><img className="esco" src={'/assets/img/esco-logo.svg'} height={14} /><span>{t('enums:dataSourceSupplier:esco')}</span></>}>
                                </Tab>

                                <Tab
                                    disabled={sources?.filter(x => x.supplier == DataSourceSupplier.LinkedIn).length == 0}
                                    eventKey={DataSourceSupplier.LinkedIn}
                                    title={<><i className="fab fa-linkedin" /><span>{t('enums:dataSourceSupplier:linkedin')}</span></>}>
                                </Tab>

                                <Tab
                                    disabled={sources?.filter(x => x.supplier == DataSourceSupplier.Wihv).length == 0}
                                    eventKey={DataSourceSupplier.Wihv}
                                    title={<><img className="wihv" src={'/assets/img/wihv-icon.png'} height={18} /><span>{t('enums:dataSourceSupplier:wihv')}</span></>}>
                                </Tab>
                            </Tabs>
                        </div>
                    }
                </Tab>
                <Tab
                    eventKey={DataSourceType.Vacancy}
                    disabled={sources?.filter(x => x.type == DataSourceType.Vacancy).length == 0}
                    className="vacancy-tab"
                    title={
                        <>
                            <i className="fas fa-briefcase me-2" />
                            <span>{t('enums:dataSourceType:vacancy')}</span>
                        </>
                    }>
                </Tab>
            </Tabs>
        </div>
    )
}

export default SkillSourceSelection