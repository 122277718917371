import { ConfigContext } from '@/context/config-context';
import useAuthenticatedPost from '@/hooks/useAuthenticatedPost';
import { useMsal } from '@azure/msal-react';
import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import {  useNavigate} from 'react-router-dom';
import useSWR from 'swr';
import { useLocation } from 'react-router-dom';
import { TeamleaderFocusAuthorization } from '../../../model/teamleader-focus/teamleader-focus-authorization';
import { useDocumentTitle } from "@/hooks/useDocumentTitle";


function ShowApiKeyStep() {

    useDocumentTitle(t('documentTitles:connectorAdd'));
    const location = useLocation();
    const navigate = useNavigate();
    const { postRequest } = useAuthenticatedPost();
    const configContext = useContext(ConfigContext);
    const { instance } = useMsal();
    const [connector, setConnectorInformation] = useState<TeamleaderFocusAuthorization>({clientId: '', clientSecret: '', returnUrl: '', authorizeUrl: '', portalUrl: ''});  
    const [returnUrl, setReturnUrl] = useState<string>('');  
    const [validated, setValidated] = useState(false);

    const formRef = createRef<HTMLFormElement>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { data, isLoading, error, mutate } = useSWR(configContext.configBody.api.endpoints.connectors.setup.replace('{name}', 'teamleaderfocus').replace('{step}', 'security'), { shouldRetryOnError: true }); 

    useEffect(() => {
        if (!data) return;
        setConnectorInformation(data);        
    }, [data]);

    useEffect(() => {
        setReturnUrl(connector.returnUrl);    
    }, [connector]);

    useEffect(() => {
        document.body.classList.add('no-padding');

        return () => {
            document.body.classList.remove('no-padding');
        };
    }, []);

    

    const cancelChanges = () => { 
        navigate('/profile/connectors');
    };

    const saveChanges = () => {
        setValidated(true);
        
        if (formRef.current?.checkValidity()) {
            
            setIsSubmitting(true);

            connector.portalUrl = window.location.href

            postRequest(configContext.configBody.api.endpoints.connectors.setup.replace('{name}', 'teamleaderfocus').replace('{step}', 'security'), connector)
            .then((response) => {
                toast.success(t('connectors:teamleaderfocus:message:saveSuccess'));
                window.location.href = response.authorizeUrl.replace('{clientId}', connector.clientId);
                setIsSubmitting(false);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsSubmitting(false);
            }); 
        }
    };
   
    const updateTempChanges = (propertyName, newValue) => {       

        setConnectorInformation(prevCompany => ({
            ...prevCompany,
            [propertyName]: newValue
          }));

    };

    return (
        <Row className='position-relative pt-5'>
                        <Col sm={12} md={12} lg={4} xl={4} xxl={4}>
                            <div className="h2 text-wit font-heavy">{t('connectors:teamleaderfocus:add:title')}</div>
                            <p className='pt-4 pb-5 text-wit'>
                                {t('connectors:teamleaderfocus:add:description')}
                            </p>
                        </Col>
                        <Col sm={12} md={12} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 5, offset: 1 }}>
                            <Form ref={formRef} validated={validated} noValidate className='d-flex flex-column'>

                                <Form.Group as={Row} className="mt-5">
                                    <Col>
                                        <Form.Label>{t('connectors:teamleaderfocus:add:apiKey')}<span className='required'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" required value={connector.clientId ?? ''} onChange={e => updateTempChanges('clientId', e.target.value)} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
    )
}

export default ShowApiKeyStep