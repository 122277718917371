import { ConfigContext } from '@/context/config-context';
import useAuthenticatedPost from '@/hooks/useAuthenticatedPost';
import { useMsal } from '@azure/msal-react';
import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { Company } from '../model/company';
import { Contact } from '../model/contact';
import './company-add.scss';
import PartnerFooter from "@/components/footers/partner-footer";
import useAuthenticatedPut from "@/hooks/useAuthenticatedPut";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import Header from "@/layout/header/header";

function CompanyAdd() {

    useDocumentTitle(t('documentTitles:companyAdd'));

    const navigate = useNavigate();
    const { postRequest } = useAuthenticatedPost();
    const putRequest = useAuthenticatedPut();
    const configContext = useContext(ConfigContext);
    const { instance } = useMsal();
    const [company, setCompany] = useState<Partial<Company>>({ name: '' });
    const [validated, setValidated] = useState(false);
    const [duplicateCompanyError, setDuplicateCompanyError] = useState(false);

    const formRef = createRef<HTMLFormElement>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFirstSave, setIsFirstSave] = useState(false);

    const { data, isLoading, error, mutate } = useSWR(configContext.configBody.api.endpoints.company.detail, { shouldRetryOnError: false });
    const { data: transferRequest, error: errorTransfer } = useSWR(configContext.configBody.api.endpoints.transfer.detail, { shouldRetryOnError: false });
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        resetForm();

        if (data && !isLoading && !error) {
            if (isFirstSave) {                
                navigate(`/profile/welcome`);
                setIsFirstSave(false);
            } else if (data.status === 'Updated') {                
                navigate(`/profile/detail/edit`);
            }
        }
    }, [data]);

    useEffect(() => {
        if (transferRequest) {
            setShowModal(true);
        }
    }, [transferRequest]);

    useEffect(() => {
        document.body.classList.add('no-padding');

        return () => {
            document.body.classList.remove('no-padding');
        };
    }, []);

    const handleAccept = async () => {
        setIsSubmitting(true);
        putRequest(configContext.configBody.api.endpoints.transfer.detail, { accept: true })
            .then((response) => {
                toast.success(t('company:transferRequest:acceptSuccess'));
                mutate(response);
                setShowModal(false);
                navigate(`/profile/detail/`, { state: { edit: true } });
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('company:transferRequest:acceptError'));
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const saveChanges = () => {

        setIsFirstSave(true);
        setValidated(true);
        
        if (formRef.current?.checkValidity()) {
            
            const account = instance.getActiveAccount();
            setIsSubmitting(true);

            postRequest(configContext.configBody.api.endpoints.company.detail, company)
                .then((response) => {
                    toast.success(t('company:toasts:saveSuccess'));
                    if (response.id) {
                        mutate(response);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setDuplicateCompanyError(true);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };

    const updateTempChanges = (propertyName, newValue) => {
        let updatedCompany: Partial<Company> = {
            ...company,
            [propertyName]: newValue
        };

        setCompany(updatedCompany);
    };

    function resetForm() {
        setCompany({ name: ''});
        setValidated(false);
        setDuplicateCompanyError(false);
    }

    return (
        <div className="company-add">

            <Container fluid className='add-container position-relative'>
                <Container>
                    <div className='header-img full position-absolute px-0'>
                        <img src={'/assets/img/WIHV_3D_Visual_Rood.jpg'} alt='' />
                    </div>

                    <div className="position-relative">
                        <Header />
                    </div>

                    <Row className='position-relative pt-5'>
                        <Col sm={12} md={12} lg={4} xl={4} xxl={4}>
                            <div className="h2 text-wit font-heavy">{t('company:add:title')}</div>
                            <p className='pt-4 pb-5 text-wit'>
                                {t('company:add:description')}
                            </p>
                        </Col>
                        <Col sm={12} md={12} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 5, offset: 1 }}>
                            <Form ref={formRef} validated={validated} noValidate className='d-flex flex-column'>
                                <Form.Group as={Row} className="mt-5">
                                    <Col>
                                        <Form.Label>{t('company:details:labelCompanyName')}</Form.Label>
                                        <Form.Control type="text" placeholder="" value={company.companyName ?? ''} onChange={e => updateTempChanges('companyName', e.target.value)} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-4">
                                    <Col>
                                        <Form.Label>{t('company:contactDetails:labelPhone')}<span className='required'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" required value={company.phone ?? ''} onChange={e => updateTempChanges('phone', e.target.value)} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-4">
                                    <Col>
                                        <Form.Label>{t('company:contactDetails:labelStreet')}</Form.Label>
                                        <Form.Control type="text" placeholder="" value={company.street ?? ''} onChange={e => updateTempChanges('street', e.target.value)} />
                                    </Col>
                                    <Col>
                                        <Form.Label>{t('company:contactDetails:labelHouseNumber')}</Form.Label>
                                        <Form.Control type="text" placeholder="" value={company.houseNumber ?? ''} onChange={e => updateTempChanges('houseNumber', e.target.value)} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-4">
                                    <Col>
                                        <Form.Label>{t('company:contactDetails:labelPostalcode')}</Form.Label>
                                        <Form.Control type="text" placeholder="" value={company.postalCode ?? ''} onChange={e => updateTempChanges('postalCode', e.target.value)} />
                                    </Col>
                                    <Col>
                                        <Form.Label>{t('company:contactDetails:labelCity')}</Form.Label>
                                        <Form.Control type="text" placeholder="" value={company.city ?? ''} onChange={e => updateTempChanges('city', e.target.value)} />
                                    </Col>
                                </Form.Group>

                                <div className="d-flex justify-content-between align-items-center my-5">
                                    <Button className='transparent' variant="wit" onClick={() => instance.logoutRedirect()}>
                                        {t('company:add:cancel')}
                                    </Button>
                                    <Button className='' variant="zwartblauw" disabled={isSubmitting} onClick={() => saveChanges()}>
                                        {t('company:add:createCompany')} <i className="fas fa-chevron-right fa-md ms-2"></i>
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <PartnerFooter />
        </div>
    )
}

export default CompanyAdd