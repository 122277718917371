import SettingsProvider from '@/context/settings-provider';
import SettingsOverview from './settings-overview';

function SettingsModule() {
    
    return (
        <SettingsProvider>
            <SettingsOverview />
        </SettingsProvider>
    )
}

export default SettingsModule;