import { ConfigContext } from '@/context/config-context';
import useAuthenticatedPost from '@/hooks/useAuthenticatedPost';
import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { ExactGlobeDatabaseInfo } from "@/connectors/model/exactglobe/exactglobe-database-info";
import { Handler, useWizard, } from "@/components/wizard";
import { ConnectorExactContext } from '@/connectors/components/connector-exact/context/connector-exact-context';

function DatabaseInfo() {

    const { handleStep } = useWizard();
    const connectorExactContext = useContext(ConnectorExactContext);
    const { postRequest } = useAuthenticatedPost();
    const configContext = useContext(ConfigContext);
    const [databaseInfo, setDatabaseInfo] = useState<Partial<ExactGlobeDatabaseInfo>>({});
    const [validated, setValidated] = useState(false);
    const formRef = createRef<HTMLFormElement>();

    const { data, isLoading, error, mutate } = useSWR(configContext.configBody.api.endpoints.company.detail, { shouldRetryOnError: false });

    useEffect(() => {
        setDatabaseInfo(connectorExactContext.databaseInfo);
        resetForm();
    }, [data]);

    useEffect(() => {
        document.body.classList.add('no-padding');
        setDatabaseInfo(connectorExactContext.databaseInfo);
        return () => {
            document.body.classList.remove('no-padding');
        };
    }, []);

    const validateDatabaseSettings = async () => {
        var valid = true;

        return { valid };
    };

    const saveChanges = async (): Promise<boolean> => {
        setValidated(true);
        connectorExactContext.updateDatabaseInfo(databaseInfo as ExactGlobeDatabaseInfo);
        if (formRef.current?.checkValidity()) {

            await validateDatabaseSettings().then((result) => {
                if (!result.valid) {
                    toast.success(t('connectors:exactglobe:message:invalidSettings'));
                    return;
                }
                postRequest(configContext.configBody.api.endpoints.connectors.setup.replace('{name}', 'exactglobe').replace('{step}', 'dbinfo'), databaseInfo)
                    .then((response) => {
                        toast.success(t('connectors:exactglobe:message:saveSuccess'));
                        mutate(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(t('company:transferRequest:acceptError'));
                        throw error;
                    });
            });
            return true;
        }
        else {
            return false;
            //throw new Error('Form is not valid');
        }
    };

    const updateTempChanges = (propertyName, newValue) => {
        let updatedInfo: Partial<ExactGlobeDatabaseInfo> = {
            ...databaseInfo,
            [propertyName]: newValue
        };

        setDatabaseInfo(updatedInfo);
    };

    function resetForm() {
        setDatabaseInfo({ sqlServerHostName: '', databaseName: '' });
        setValidated(false);
    }

    handleStep(async () => {
        return await saveChanges();
    });

    return (
        <>
            <Row className='position-relative pt-5'>
                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <h2>
                        {t('connectors:exactglobe:add:description')}
                    </h2>
                </Col>
            </Row>
            <Row className='position-relative pt-3'>
                <Col sm={12} md={12} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 5, offset: 1 }}>
                    <Form ref={formRef} validated={validated} noValidate className='d-flex flex-column'>
                        <Form.Group as={Row} className="mt-5">
                            <Col>
                                <Form.Label>{t('connectors:exactglobe:add:sqlServerHostName')}<span className='required'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="" required value={databaseInfo.sqlServerHostName ?? ''} onChange={e => updateTempChanges('sqlServerHostName', e.target.value)} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                            <Col>
                                <Form.Label>{t('connectors:exactglobe:add:databaseName')}<span className='required'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="" required value={databaseInfo.databaseName ?? ''} onChange={e => updateTempChanges('databaseName', e.target.value)} />
                            </Col>
                        </Form.Group>

                        {/* {validated && 
                        <div className='error-info p-4 mt-4'>
                            <i className="fas fa-info-circle fa-lg me-2"></i>
                            <span>{t('company:add:alreadyRepresented1')}</span>
                            <Link className='text-decoration-underline font-bold' to='/contact' target="_blank" rel="noreferrer">{t('company:add:alreadyRepresented2')}</Link>
                            <span>{t('company:add:alreadyRepresented3')}</span>
                        </div>
                    }                    */}
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default DatabaseInfo