import { Route, Routes } from 'react-router-dom';
import ConnectorsOverview from './connectors-overview';
import FunctionEdit from './components/function-edit';
import FunctionProvider from '@/context/function-provider';
import ConnectorExactGlobeComponent from './components/connector-exact/connector-exactglobe-component';
import ConnectorTeamLeaderFocusComponent from './components/connector-teamleaderfocus/connector-teamleaderfocus-component';

function ConnectorsModule() {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<ConnectorsOverview />} />
                <Route path={"add/teamleaderfocus"} element={
                    <ConnectorTeamLeaderFocusComponent isEditMode={false} />
                } />
                <Route path={"edit/teamleaderfocus"} element={
                    <ConnectorTeamLeaderFocusComponent isEditMode={true} />
                } />
                <Route path={"add/exactglobe"} element={
                    <ConnectorExactGlobeComponent />
                } />
                <Route path={"edit/exactglobe"} element={
                    <ConnectorExactGlobeComponent />
                } />
                <Route path={"edit/temp"} element={
                    <FunctionProvider>
                        <FunctionEdit />
                    </FunctionProvider>
                } />               
            </Route>
        </Routes>
    )
}

export default ConnectorsModule;