import FormCard from '@/components/cards/form-card';
import { FunctionContext } from '@/context/function-context';
import { useVendorTranslation } from '@/general/i18n/translation-helpers';
import { useContext, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { Certification } from '../model/certification';
import { EmployerFunction } from '../model/employer-function';
import { Vendor } from '../model/vendor';
import './function-details-card.scss';

type CertificationFormProps = {
    index: number;
    tempChanges: EmployerFunction;
    onUpdate: (event: any) => void;
};

const CertificationForm = ({ tempChanges, index, onUpdate }: CertificationFormProps) => {

    const { t } = useTranslation();
    const { translateVendor } = useVendorTranslation();
    const vendors = Object.values(Vendor).filter(value => !isNaN(Number(value)));
    const [customCertification, setCustomCertification] = useState(false);

    const handleSelectedOption = (selectedOption: any) => {
        if (selectedOption) {
            tempChanges.certifications[index] = selectedOption.value;
            onUpdate(tempChanges.certifications);
        }
    }

    const handleCreatedOption = (input: string) => {
        if (input) {
            setCustomCertification(true);
            tempChanges.certifications[index] = { name: input, vendor: Vendor.Other } as Certification;
            onUpdate(tempChanges.certifications);
        }
    }

    const handleCertificationChange = (event: any, index: number, property: string) => {
        tempChanges.certifications[index][property] = event.target?.value;
        onUpdate(tempChanges.certifications);
    }

    const removeCertification = (index: number) => {
        tempChanges.certifications = tempChanges.certifications.filter((item, i) => i !== index);
        onUpdate(tempChanges.certifications);
    }

    const getCertifications = () => {
        return certifications.map((c, index) => ({ label: `${c.code} - ${c.name}`, value: c }));
    }

    return (
        <div className={`certification-item-form ${customCertification ? 'custom-certification' : ''}`}>

            <Form.Group as={Row} className={`mb-3`}>
                <Col sm={10}>
                    {customCertification ?
                        <>
                            <Form.Label>{t('functions:detail:certifications:labelName')}</Form.Label>
                            <Form.Control type="text" value={tempChanges.certifications[index]?.name ?? ''} onChange={(e) => handleCertificationChange(e, index, 'name')} />
                        </>
                        :
                        <CreatableSelect
                            placeholder={t('functions:detail:certifications:placeholderSearch')}
                            className="certification-select"
                            value={tempChanges.certifications[index]?.name ? { label: tempChanges.certifications[index].name, value: tempChanges.certifications[index] } : null}
                            formatCreateLabel={(inputValue) => <span>{t('general:create')}: "<span className='font-bold'>{inputValue}</span>"</span>}
                            isClearable={false}
                            isSearchable={true}
                            options={getCertifications()}
                            onChange={(s) => handleSelectedOption(s)}
                            onCreateOption={(s) => handleCreatedOption(s)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: '0.5rem !important',
                                    boxShadow: 'none'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#0c1732',
                                }),
                            }}
                        />}
                </Col>
                <Col sm={2} className='d-flex align-items-end'>
                    <Button onClick={() => removeCertification(index)} className="btn-delete"><i className="fa-solid fa-trash fa-lg" /></Button>
                </Col>
            </Form.Group>

            {
                customCertification &&
                <Form.Group as={Row} className='mt-3'>
                    <Col sm={6}>
                        <Form.Label>{t('functions:detail:certifications:labelCode')}</Form.Label>
                        <Form.Control type="text" value={tempChanges.certifications[index]?.code ?? ''} onChange={(e) => handleCertificationChange(e, index, 'code')} />
                    </Col>
                    <Col sm={6}>
                        <Form.Label>{t('functions:detail:certifications:labelVendor')}</Form.Label>
                        <Form.Select value={tempChanges.certifications[index].vendor ?? ''} onChange={(e) => handleCertificationChange(e, index, 'vendor')} required>
                            {vendors.map((v, index) => (
                                <option key={index} value={v}>
                                    {translateVendor(+v)}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Form.Group>
            }
        </div>
    );
};

function FunctionCertifications() {

    const { t } = useTranslation();
    const context = useContext(FunctionContext);

    const updateTempChanges = (certifications: Certification[]) => {
        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            certifications: [...certifications]
        };

        context.updateChanges(updatedFunction);
    }

    const addCertification = () => {

        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            certifications: [...context.tempChanges?.certifications ?? [], {} as Certification]
        };

        context.updateChanges(updatedFunction);
    }

    return (
        <FormCard title={t('functions:detail:certifications:title')} className='certifications'>
            {context.editable ?
                <>
                    {(!context.tempChanges?.certifications || context.tempChanges?.certifications?.length === 0) &&
                        <div className='field view'>{t('functions:detail:certifications:noCertifications')}</div>
                    }
                    {context.tempChanges?.certifications?.map((item, i) => (
                        <CertificationForm tempChanges={context.tempChanges} index={i} onUpdate={updateTempChanges} key={i} />
                    ))}
                    <Row>
                        <Col className='mt-4'>
                            <Button onClick={addCertification} className="btn btn-primary-outline"><i className="fa-solid fa-plus fa-lg me-2" />{t('functions:detail:certifications:addCertification')}</Button>
                        </Col>
                    </Row>
                </>
                : <div>
                    {(!context.employerFunction?.certifications || context.employerFunction?.certifications?.length === 0) &&
                        <div className='field view'>{t('functions:detail:certifications:noCertifications')}</div>
                    }
                    {context.employerFunction?.certifications?.map((item, i) => (
                        <div key={i} className='pb-2'>
                            {item.vendor == Vendor.Microsoft && <i className='fa-brands fa-microsoft me-2' />}
                            {item.vendor == Vendor.LinkedIn && <i className='fa-brands fa-linkedin me-2' />}
                            {item.vendor == Vendor.Other && <i className='fa-solid fa-circle-question me-2' />}
                            <span className="font-bold me-2">{item.code}</span>
                            <span>{item.name}</span>
                        </div>
                    ))}
                </div>
            }
        </FormCard>
    );
}

export default FunctionCertifications;

const certifications: Certification[] = [
    {
        "code": "AZ-104",
        "name": "Microsoft Azure Administrator",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "AZ-204",
        "name": "Developing Solutions for Microsoft Azure",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "AZ-303",
        "name": "Microsoft Azure Architect Technologies",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "AZ-304",
        "name": "Microsoft Azure Architect Design",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "AZ-400",
        "name": "Designing and Implementing Microsoft DevOps Solutions",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "AZ-500",
        "name": "Microsoft Azure Security Technologies",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "AZ-600",
        "name": "Configuring and Operating a Hybrid Cloud with Microsoft Azure Stack Hub",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "AZ-900",
        "name": "Microsoft Azure Fundamentals",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "DA-100",
        "name": "Analyzing Data with Microsoft Power BI",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "DP-100",
        "name": "Designing and Implementing a Data Science Solution on Azure",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "DP-200",
        "name": "Implementing an Azure Data Solution",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "DP-201",
        "name": "Designing an Azure Data Solution",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "DP-300",
        "name": "Administering Relational Databases on Microsoft Azure",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "DP-900",
        "name": "Microsoft Azure Data Fundamentals",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-210",
        "name": "Microsoft Dynamics 365 Sales",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-220",
        "name": "Microsoft Dynamics 365 Marketing",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-230",
        "name": "Microsoft Dynamics 365 Customer Service",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-240",
        "name": "Microsoft Dynamics 365 Field Service",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-300",
        "name": "Microsoft Dynamics 365: Core Finance and Operations",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-310",
        "name": "Microsoft Dynamics 365 Finance",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-320",
        "name": "Microsoft Dynamics 365 Supply Chain Management",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-330",
        "name": "Microsoft Dynamics 365 Supply Chain Management, Manufacturing",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-400",
        "name": "Microsoft Power Apps + Dynamics 365 Developer",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-500",
        "name": "Microsoft Dynamics 365: Finance and Operations Apps Developer",
        "vendor": Vendor.Microsoft
    },
    {
        "code": "MB-600",
        "name": "Microsoft Dynamics 365 + Power Platform Solution Architect",
        "vendor": Vendor.Microsoft
    }
];