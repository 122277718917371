import FormCard from '@/components/cards/form-card';
import { FunctionContext } from '@/context/function-context';
import { useLanguageLevelTranslation } from '@/general/i18n/translation-helpers';
import { t } from 'i18next';
import { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { EmployerFunction } from '../model/employer-function';
import { SelectedLanguage } from '../model/language';
import { LanguageLevel } from '../model/language-level';
import './function-details-card.scss';

const languages: string[] = ["nl", "en", "de", "fr", "es", "ua"];

type LanguagesFormProps = {
    index: number;
    tempChanges: EmployerFunction;
    languages: string[];
    onUpdate: (event: any) => void;
};

const LanguagesForm = ({ tempChanges, index, languages, onUpdate }: LanguagesFormProps) => {

    const { translateLanguageLevel } = useLanguageLevelTranslation();

    const getLanguageLevels = () => {
        let levels: any[] = [];
        const languageLevels = Object.values(LanguageLevel).filter(value => !isNaN(Number(value)));
        languageLevels.forEach((level, index) => {
            levels.push({ label: translateLanguageLevel(+level), value: level });
        });
        return levels;
    }

    const handleLanguageChange = (event: any, index: number) => {
        if (event.target.selectedIndex != 0) {

            let isoCode = event.target.selectedOptions[0]?.value;
            tempChanges.languages[index].isoCode = isoCode;
            onUpdate(tempChanges.languages);
        }
    }

    const handleLanguageProficiencyChange = (ll: LanguageLevel, index: number, property: string) => {
        tempChanges.languages[index][property] = ll;
        onUpdate(tempChanges.languages);
    }

    const removeLanguage = (index: number) => {
        tempChanges.languages = tempChanges.languages.filter((item, i) => i !== index);
        onUpdate(tempChanges.languages);
    }

    return (
        <div className='language-item-form'>
            <Form.Group as={Row}>
                <Col sm={10}>
                    <Form.Label>{t('functions:detail:languages:labelIsoCode')}</Form.Label>
                    <Form.Select value={tempChanges.languages[index].isoCode ?? ''} onChange={(e) => handleLanguageChange(e, index)} required>
                        <option key={-1} value="">{t('functions:detail:languages:placeholderIsoCode')}</option>
                        {
                            languages.map((item, languageIndex) => (<option key={languageIndex} value={item}>{t(`languages:${item}`)}</option>))
                        }
                    </Form.Select>
                </Col>
                <Col sm={2} className='d-flex align-items-end'>
                    <Button onClick={() => removeLanguage(index)} className="btn-delete"><i className="fa-solid fa-trash fa-lg" /></Button>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='general-proficiency mt-3'>
                <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <div className='language-proficiency'>
                        <Form.Label>{t('functions:detail:languages:general')}</Form.Label>
                        <Select
                            placeholder={t('functions:detail:languages:placeholderLanguageLevel')}
                            className="certification-select"
                            value={tempChanges.languages[index]?.general ? { label: translateLanguageLevel(tempChanges.languages[index].general), value: tempChanges.languages[index].general } : null}
                            isClearable={false}
                            isSearchable={true}
                            options={getLanguageLevels()}
                            onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'general')}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: '0rem 0.5rem',
                                    boxShadow: 'none'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#0c1732',
                                }),
                            }}
                        />
                    </div>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col>
                    <div className='language-proficiency'>
                        <Form.Label>{t('functions:detail:languages:reading')}</Form.Label>
                        <Select
                            placeholder={t('functions:detail:languages:placeholderLanguageLevel')}
                            className="certification-select"
                            value={tempChanges.languages[index]?.reading ? { label: translateLanguageLevel(tempChanges.languages[index].reading), value: tempChanges.languages[index].reading } : null}
                            isClearable={false}
                            isSearchable={true}
                            options={getLanguageLevels()}
                            onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'reading')}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: '0rem 0.5rem',
                                    boxShadow: 'none'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#0c1732',
                                }),
                            }}
                        />
                    </div>

                    <div className='language-proficiency'>
                        <Form.Label>{t('functions:detail:languages:writing')}</Form.Label>
                        <Select
                            placeholder={t('functions:detail:languages:placeholderLanguageLevel')}
                            className="certification-select"
                            value={tempChanges.languages[index]?.writing ? { label: translateLanguageLevel(tempChanges.languages[index].writing), value: tempChanges.languages[index].writing } : null}
                            isClearable={false}
                            isSearchable={true}
                            options={getLanguageLevels()}
                            onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'writing')}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: '0rem 0.5rem',
                                    boxShadow: 'none'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#0c1732',
                                }),
                            }}
                        />
                    </div>

                    <div className='language-proficiency'>
                        <Form.Label>{t('functions:detail:languages:listening')}</Form.Label>
                        <Select
                            placeholder={t('functions:detail:languages:placeholderLanguageLevel')}
                            className="certification-select"
                            value={tempChanges.languages[index]?.listening ? { label: translateLanguageLevel(tempChanges.languages[index].listening), value: tempChanges.languages[index].listening } : null}
                            isClearable={false}
                            isSearchable={true}
                            options={getLanguageLevels()}
                            onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'listening')}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: '0rem 0.5rem',
                                    boxShadow: 'none'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#0c1732',
                                }),
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <div className='language-proficiency'>
                        <Form.Label>{t('functions:detail:languages:speechTransferInformation')}</Form.Label>
                        <Select
                            placeholder={t('functions:detail:languages:placeholderLanguageLevel')}
                            className="certification-select"
                            value={tempChanges.languages[index]?.speechTransferInformation ? { label: translateLanguageLevel(tempChanges.languages[index].speechTransferInformation), value: tempChanges.languages[index].speechTransferInformation } : null}
                            isClearable={false}
                            isSearchable={true}
                            options={getLanguageLevels()}
                            onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'speechTransferInformation')}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: '0rem 0.5rem',
                                    boxShadow: 'none'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#0c1732',
                                }),
                            }}
                        />
                    </div>

                    <div className='language-proficiency'>
                        <Form.Label>{t('functions:detail:languages:speechDialogue')}</Form.Label>
                        <Select
                            placeholder={t('functions:detail:languages:placeholderLanguageLevel')}
                            className="certification-select"
                            value={tempChanges.languages[index]?.speechDialogue ? { label: translateLanguageLevel(tempChanges.languages[index].speechDialogue), value: tempChanges.languages[index].speechDialogue } : null}
                            isClearable={false}
                            isSearchable={true}
                            options={getLanguageLevels()}
                            onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'speechDialogue')}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    borderRadius: '25px',
                                    padding: '0rem 0.5rem',
                                    boxShadow: 'none'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#0c1732',
                                }),
                            }}
                        />
                    </div>
                </Col>
            </Form.Group>
        </div>
    );
};

function FunctionLanguages() {

    const context = useContext(FunctionContext);
    const { translateLanguageLevel } = useLanguageLevelTranslation();

    const updateTempChanges = (languages: SelectedLanguage[]) => {
        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            languages: [...languages]
        };

        context.updateChanges(updatedFunction);
    }

    const addLanguage = () => {

        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            languages: [...context.tempChanges?.languages ?? [], {} as SelectedLanguage]
        };

        context.updateChanges(updatedFunction);
    }

    const getUnselectedLanguages = (currentLanguage: string) => {
        return languages?.filter(l =>
            !context.tempChanges?.languages?.some(pl => pl.isoCode === l)
            || l === currentLanguage) ?? [];
    }

    const canAddAnotherLanguage = () => {
        return context.tempChanges?.languages?.length != languages?.length;
    }

    return (
        <FormCard title={t('functions:detail:languages:title')} className='languages'>
            {context.editable ?
                <>
                    {(!context.tempChanges?.languages || context.tempChanges?.languages?.length === 0) &&
                        <div className='field view'>{t('functions:detail:languages:noLanguages')}</div>
                    }
                    {context.tempChanges?.languages?.map((item, i) => (
                        <LanguagesForm tempChanges={context.tempChanges} index={i} onUpdate={updateTempChanges} languages={getUnselectedLanguages(item.isoCode)} key={i} />
                    ))}
                    <Row>
                        <Col className='mt-4'>
                            <Button onClick={addLanguage} className="btn btn-primary-outline" disabled={!canAddAnotherLanguage()}><i className="fa-solid fa-plus fa-lg me-2" />{t('functions:detail:languages:addLanguage')}</Button>
                        </Col>
                    </Row>
                </>
                :
                <div>
                    {(!context.employerFunction?.languages || context.employerFunction?.languages?.length === 0) &&
                        <div className='field view'>{t('functions:detail:languages:noLanguages')}</div>
                    }

                    {context.employerFunction?.languages?.map((item, i) => (
                        <div key={i}>
                            <div className='font-bold text-donkerblauw'>{t(`languages:${item.isoCode}`)}</div>
                            <Row className='language-proficiency view pt-2'>
                                <Col sm={12} md={12} lg={12} xl={12} xxl={12} className='pb-3'>
                                    <div className='language-proficiency-type'>
                                        <span className='language-proficiency-label'>{t('functions:detail:languages:general')}</span>
                                        <span className='language-proficiency-value'>{translateLanguageLevel(+item.general)}</span>
                                    </div>
                                </Col>
                                <Col className='levels'>
                                    <div className='language-proficiency-type'>
                                        <span className='language-proficiency-label'>{t('functions:detail:languages:reading')}</span>
                                        <span className='language-proficiency-value'>{translateLanguageLevel(+item.reading)}</span>
                                    </div>
                                    <div className='language-proficiency-type'>
                                        <span className='language-proficiency-label'>{t('functions:detail:languages:writing')}</span>
                                        <span className='language-proficiency-value'>{translateLanguageLevel(+item.writing)}</span>
                                    </div>
                                    <div className='language-proficiency-type'>
                                        <span className='language-proficiency-label'>{t('functions:detail:languages:listening')}</span>
                                        <span className='language-proficiency-value'>{translateLanguageLevel(+item.listening)}</span>
                                    </div>
                                    <div className='language-proficiency-type'>
                                        <span className='language-proficiency-label'>{t('functions:detail:languages:speechTransferInformation')}</span>
                                        <span className='language-proficiency-value'>{translateLanguageLevel(+item.speechTransferInformation)}</span>
                                    </div>
                                    <div className='language-proficiency-type'>
                                        <span className='language-proficiency-label'>{t('functions:detail:languages:speechDialogue')}</span>
                                        <span className='language-proficiency-value'>{translateLanguageLevel(+item.speechDialogue)}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
            }
        </FormCard>
    );
}

export default FunctionLanguages;
