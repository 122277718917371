import { LoadingComponent } from "@/components/loading-component/loading-component";
import { ConfigContext } from "@/context/config-context";
import useAuthenticatedPost from "@/hooks/useAuthenticatedPost";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from 'swr';
import { Company } from "../modules/company/model/company";
import { CompanyContext, ICompanyState } from "./company-context";
import { useTranslation } from "react-i18next";
import { ProfileCompletion } from "@/general/model/profile-completion";

function CompanyProvider({ children }): React.ReactElement {

    const navigate = useNavigate();
    var configContext = useContext(ConfigContext);
    const { postRequest } = useAuthenticatedPost();
    const location = useLocation();
    const { t } = useTranslation();
    const { data, isLoading, error, mutate } = useSWR(configContext.configBody.api.endpoints.company.detail, { shouldRetryOnError: false });
    const { mutate: mutateProfileCompletion } = useSWR<ProfileCompletion>(configContext.configBody.api.endpoints.company.completion, { shouldRetryOnError: false });

    const [contextValue, setContextValue] = useState<ICompanyState>(
        {
            company: undefined,
            tempChanges: undefined,
            editable: false,
            requestInProgress: false,
            saveCompany: saveCompany,
            updateCompany: updateCompany,
            updateChanges: updateChanges,
            updateEditable: updateEditable,
        }
    );

    useEffect(() => {

        if (data) {
            
            setContextValue((prevContextValue) => {
                return {
                    ...prevContextValue,
                    company: data,
                    loading: false,
                    tempChanges: data,
                };
            });

            if (location.pathname === `/profile/detail`) {                
                const newUrl = `/profile/detail/edit`;
                navigate(newUrl);
            }
        }

    }, [data]);

    useEffect(() => {
        if (error) {
            navigate('/profile/detail/edit');
        }
    }, [error]);

    function updateEditable(editable: boolean) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                tempChanges: prevContextValue.company,
                editable: editable,
            };
        });
    }

    function updateChanges(company: Company) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                tempChanges: company,
            };
        });
    }

    function updateCompany(company: Company) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                company: company,
                tempChanges: company,
            };
        });
    }

    function saveCompany(company: Company): Promise<Company> {

        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                requestInProgress: true,
            };
        });

        return postRequest(configContext.configBody.api.endpoints.company.detail, company)
            .then((response) => {
                updateChanges(response);
                updateEditable(false);
                mutate();
                mutateProfileCompletion();
                toast.success(t('company:toasts:saveSuccess'));
                return response;
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('company:toasts:saveFailed') + ' ' + error.message);
                throw error;
            })
            .finally(() => {
                setContextValue((prevContextValue) => {
                    return {
                        ...prevContextValue,
                        requestInProgress: false,
                    };
                });
            });
    };

    if (isLoading) {
        const message = t('general:retrievingData');
        return <LoadingComponent message={message} />;
    } else {
        return (
            <CompanyContext.Provider value={contextValue}>
                {children}
            </CompanyContext.Provider>
        );
    }
}

export default CompanyProvider;