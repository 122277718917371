import NavigationBlocker from "@/components/navigation-blocker/navigation-blocker";
import { ConfigContext } from "@/context/config-context";
import Header from "@/layout/header/header";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import './feedback-container.scss';
import FeedbackSkillSelection from "./feedback-skill-selection";
import { Feedback } from "./models/feedback";
import { FeedbackEmployerFunction } from "./models/feedback-employer-function";
import { FeedbackStatus } from "./models/feedback-status";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { t } from "i18next";
import { FunctionDataSource } from "@/connectors/model/function-data-source";

function FeedbackContainer() {

    useDocumentTitle(t('documentTitles:feedbackPublic'));

    var configContext = useContext(ConfigContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const hash = queryParams.get('hash');
    const savedLanguage = localStorage.getItem('selectedLanguage');

    const [loading, setLoading] = useState<boolean>(true);
    const [started, setStarted] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [employerfunction, setEmployerFunction] = useState<FeedbackEmployerFunction>(null);
    const [sources, setSources] = useState<FunctionDataSource[]>([]);

    useEffect(() => {

        if (hash) {
            getFeedbackFunction(hash);
            getFeedbackFunctionDatasources(hash);
        } else {
            setError(t('feedback:noHashFound'));
            setLoading(false);
        }

    }, []);

    async function getFeedbackFunction(hash) {
        try {
            setLoading(true);
            const response = await fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.functions.feedbacksValidate}`, {
                credentials: "same-origin",
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
                },
                body: JSON.stringify({
                    hash: hash
                })
            });

            if (!response.ok) {
                throw new Error("Invalid response");
            }

            const data = await response.json();
            setEmployerFunction(data);
            setLoading(false);

        } catch (error) {
            setError(error.toString());
            setLoading(false);
        }
    };

    async function getFeedbackFunctionDatasources(hash) {
        try {
            setLoading(true);
            const response = await fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.sources.list}?hash=${hash}`, {
                credentials: "same-origin",
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Accept-Language': savedLanguage,
                    'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
                }
            });

            if (!response.ok) {
                throw new Error("Invalid response");
            }

            const data = await response.json();
            setSources(data);
            setLoading(false);
        } catch (error) {
            setError(error.toString());
            setLoading(false);
        }
    };

    function handleSaveFeedback(feedback: Feedback) {

        feedback.status = FeedbackStatus.Submitted;
        setIsSubmitting(true);

        return fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.functions.feedbacksList.replace('{id}', employerfunction.id)}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(feedback)
        })
            .then((response) => {
                if (response.ok) {
                    setSaved(true);
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .catch((error) => { })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    return (
        <div className="app-container">
            <div className="main-container">
                <main>
                    <Container fluid className='feedback-container position-relative pb-5'>
                        <Container>

                            <div className='header-img full position-absolute px-0'>
                                <img src={'/assets/img/WIHV_3D_Visual_Blauw_Wit.jpg'} style={{ objectPosition: 'center center' }} alt='' />
                            </div>

                            <div className="position-relative pb-5">
                                <Header />
                            </div>

                            {loading &&
                                <Row className='position-relative'>
                                    <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                        <div className="font-heavy h1 pb-3">{t('feedback:loadingTitle')}</div>
                                        <p className="pb-4">
                                            {t('feedback:loadingDescription')}
                                        </p>
                                    </Col>
                                </Row>
                            }

                            {!loading && !started && !error && (employerfunction && employerfunction.feedbacks?.length > 0) &&
                                <Row className='position-relative'>
                                    <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                        <div className="font-heavy h1 pb-3">{t('feedback:startTitle', { name: employerfunction.createdBy })}</div>
                                        <p className="h4 text-geel font-bold pb-3">{t('feedback:startSubtitle')}</p>
                                        <p className="pb-4">
                                            {t('feedback:startDescription', { name: employerfunction.createdBy, functionName: employerfunction.name  })}
                                        </p>
                                        <button className="button" onClick={() => setStarted(true)}><span>{t('feedback:startBtn')}</span><i className="fas fa-chevron-right ms-3" /></button>
                                    </Col>
                                </Row>
                            }

                            {!loading && !started && error &&
                                <Row className='position-relative'>
                                    <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                        <div className="font-heavy h1 pb-3">{t('feedback:noFunctionTitle')}</div>
                                        <p className="pb-4">
                                            {t('feedback:noFunctionDescription')} - {error}
                                        </p>
                                    </Col>
                                </Row>
                            }

                            {!loading && started && (employerfunction && employerfunction.feedbacks.length > 0) && !error && !saved &&
                                <FeedbackSkillSelection employerfunction={employerfunction} sources={sources} isSubmitting={isSubmitting} saveEmployerFunctionFeedback={(e) => { handleSaveFeedback(e) }} />
                            }

                            {saved &&
                                <Row className='position-relative'>
                                    <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                        <div className="font-heavy h1 pb-5">{t('feedback:savedTitle')}</div>
                                        <Link className="button" to="/"><span>{t('feedback:savedLink')}</span><i className="fas fa-chevron-right ms-3" /></Link>
                                    </Col>
                                </Row>
                            }

                        </Container>
                    </Container>
                </main>
            </div>

            <NavigationBlocker shouldBlock={started && !saved} />
        </div>
    )
}

export default FeedbackContainer