import { CompanyContext } from "@/context/company-context";
import { ConfigContext } from "@/context/config-context";
import useAuthenticatedDelete from "@/hooks/useAuthenticatedDelete";
import useAuthenticatedPost from "@/hooks/useAuthenticatedPost";
import { useMsal } from "@azure/msal-react";
import { t } from "i18next";
import { createRef, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { CompanyTransfer } from "../model/company-transfer";
import './settings-card.scss';
import useFavoriteTalents from "@/hooks/useFavoriteTalents";
import FormCard from "@/components/cards/form-card";

function AccountSettings() {

    const { accounts } = useMsal();
    const { clearFavorites } = useFavoriteTalents();
    const navigate = useNavigate();
    const deleteRequest = useAuthenticatedDelete();
    const { postRequest } = useAuthenticatedPost();
    const configContext = useContext(ConfigContext);
    const companyContext = useContext(CompanyContext);
    const identityProvider = accounts[0]?.idTokenClaims["idp"];

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    // Transfer company
    const formRef = createRef<HTMLFormElement>();
    const [validated, setValidated] = useState(false);
    const [companyTransfer, setCompanyTransfer] = useState<CompanyTransfer>(null);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showCancelTransferModal, setShowCancelTransferModal] = useState(false);
    const { mutate: mutateCompany } = useSWR(configContext.configBody.api.endpoints.company.detail);

    const errorMessages = {
        "ERR_COMPANY_ALREADY_TRANSFERRED": t('settings:toasts:companyAlreadyTransferred'),
        "ERR_EMAIL_ALREADY_USED": t('settings:toasts:emailAlreadyUsed'),
        "ERR_USER_ALREADY_CONNECTED_TO_COMPANY": t('settings:toasts:userAlreadyConnectedToCompany'),
    };

    const handleDelete = () => {

      

        setShowConfirmModal(false);
        setIsSubmitting(true);

        deleteRequest(configContext.configBody.api.endpoints.company.detail)
            .then(() => {
                companyContext.updateCompany(undefined);
                mutateCompany(null);
                mutate(() => true, undefined, false);
                clearFavorites();
                toast.success(t('settings:toasts:deleteCompanySuccess'));
                navigate('/profile/detail/');
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('settings:toasts:deleteCompanyFailed') + ' ' + error.message);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    const handleTransfer = () => {

        setValidated(true);

        if (formRef.current?.checkValidity()) {

            setIsSubmitting(true);

            postRequest(configContext.configBody.api.endpoints.transfer.detail, companyTransfer)
                .then((response) => {
                    mutateCompany(configContext.configBody.api.endpoints.company.detail);
                    setShowTransferModal(false);
                    toast.success(t('settings:toasts:transferCompanySuccess'));
                })
                .catch((error) => {
                    console.error(error);
                    let errorMessage = errorMessages[error.response?.data?.errorCode];

                    if (!errorMessage) {
                        errorMessage = t('settings:toasts:transferCompanyFailed') + ' ' + error.message;
                    }

                    toast.error(errorMessage);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    }

    const cancelTransfer = () => {

        setIsSubmitting(true);

        deleteRequest(configContext.configBody.api.endpoints.transfer.detail)
            .then(() => {
                mutateCompany(configContext.configBody.api.endpoints.company.detail);
                toast.success(t('settings:toasts:cancelTransferCompanySuccess'));
                setShowCancelTransferModal(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('settings:toasts:cancelTransferCompanyFailed') + ' ' + error.message);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (showTransferModal) {
            setValidated(false);
            setCompanyTransfer({ name: '', email: '' } as CompanyTransfer);
        }
    }, [showTransferModal]);

    return (
        <>
            <FormCard title={t('settings:account:title')} className="settings-card">
                <p>{t('settings:account:description')}</p>

                <div className="setting mt-4">
                    <div className="h5 font-bold mb-2">{t('settings:account:deleteCompanyTitle')}</div>
                    <p className="mb-3">{t('settings:account:deleteCompanyDescription')}</p>
                   
                </div>

                <div className="setting mt-4">
                    <div className="h5 font-bold mb-2">{t('settings:account:transferCompanyTitle')}</div>
                    <p className="mb-3">{t('settings:account:transferCompanyDescription')}</p>
                    
                </div>

                <div className="setting mt-4">
                    <div className="h5 font-bold mb-2">{t('settings:account:changePasswordTitle')}</div>
                    <p className="mb-3">
                        {
                            identityProvider === 'linkedin.com' ?
                                t('settings:account:changePasswordDescriptionLinkedIn') :
                                t('settings:account:changePasswordDescription')
                        }
                    </p>
                    <a href={identityProvider === 'linkedin.com' ? 'https://www.linkedin.com/uas/request-password-reset' : configContext.configBody.ad.changePasswordUrl} target="_blank" rel="noopener noreferrer" className="btn btn-primary-outline transparent">
                        {t('settings:account:changePassword')}
                    </a>
                </div>
            </FormCard>

            <Modal className="confirm-modal wihv-modal" show={showConfirmModal} onHide={() => setShowConfirmModal(false)} backdrop="static" centered>
                <Modal.Header>
                    <div className='h3 text-donkerblauw'>{t('settings:account:deleteCompanyTitle')}</div>
                </Modal.Header>

                <Modal.Body as={Container}>
                    <p>{t('settings:account:confirmDeleteCompany')}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary-outline transparent" onClick={() => setShowConfirmModal(false)}>
                        {t('general:no')}
                    </Button>
                    <Button variant="secondary" disabled={isSubmitting} onClick={() => handleDelete()}>
                        {t('general:yes')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="confirm-modal wihv-modal" show={showTransferModal} onHide={() => setShowTransferModal(false)} backdrop="static" centered>
                <Modal.Header>
                    <div className='h3 text-donkerblauw'>{t('settings:account:transferCompanyTitle')}</div>
                </Modal.Header>

                <Modal.Body as={Container}>
                    <p>{t('settings:account:infoTransferCompany')}</p>

                    <Form ref={formRef} validated={validated} noValidate className='d-flex flex-column'>
                        <Form.Group as={Row} className="mt-1">
                            <Col>
                                <Form.Label>{t('settings:account:labelName')}<span className='required'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="" required value={companyTransfer?.name ?? ''} onChange={e => setCompanyTransfer((prev) => { return { ...prev, name: e.target.value } })} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-4">
                            <Col>
                                <Form.Label>{t('settings:account:labelEmail')}<span className='required'>*</span></Form.Label>
                                <Form.Control type="email" placeholder="" required value={companyTransfer?.email ?? ''} onChange={e => setCompanyTransfer((prev) => { return { ...prev, email: e.target.value } })} />
                            </Col>
                        </Form.Group>
                    </Form>

                    <p className="mt-4 font-bold">{t('settings:account:confirmTransferCompany')}</p>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary-outline transparent" onClick={() => setShowTransferModal(false)}>
                        {t('general:no')}
                    </Button>
                    <Button variant="secondary" disabled={isSubmitting} onClick={() => handleTransfer()}>
                        {t('general:yes')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="confirm-modal wihv-modal" show={showCancelTransferModal} onHide={() => setShowCancelTransferModal(false)} backdrop="static" centered>
                <Modal.Header>
                    <div className='h3 text-donkerblauw'>{t('settings:account:cancelTransferCompanyTitle')}</div>
                </Modal.Header>

                <Modal.Body as={Container}>
                    <p>{t('settings:account:infoCancelTransferCompany')}</p>

                    <p className="mt-4 font-bold">{t('settings:account:confirmCancelTransferCompany')}</p>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary-outline transparent" onClick={() => setShowCancelTransferModal(false)}>
                        {t('general:no')}
                    </Button>
                    <Button variant="secondary" disabled={isSubmitting} onClick={() => cancelTransfer()}>
                        {t('general:yes')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AccountSettings