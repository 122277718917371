import { ConfigContext } from '@/context/config-context';
import useAuthenticatedPost from '@/hooks/useAuthenticatedPost';
import { useMsal } from '@azure/msal-react';
import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import {  useNavigate} from 'react-router-dom';
import useSWR from 'swr';
import { useLocation } from 'react-router-dom';
import { TeamleaderFocusAuthorization } from '../../../model/teamleader-focus/teamleader-focus-authorization';
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { ConnectorTeamleaderFocusContext } from '../context/connector-teamleaderfocus-context';


function AuthorizeStep() {

    useDocumentTitle(t('documentTitles:connectorAdd'));
    
    
    const connectorContext = useContext(ConnectorTeamleaderFocusContext);   
    const [validated, setValidated] = useState(false);
    const formRef = createRef<HTMLFormElement>();
    const [autorizeUrl, setAuthorizeUrl] = useState<string>('');         

    useEffect(() => {
        debugger;
        setAuthorizeUrl(connectorContext.authorizationInfo?.authorizeUrl?.replace('{clientId}', connectorContext.authorizationInfo?.clientId));    
    }, []);

    useEffect(() => {
        document.body.classList.add('no-padding');

        return () => {
            document.body.classList.remove('no-padding');
        };
    }, []);

    const authorize = () => {
        setValidated(true);
        window.location.href = autorizeUrl;        
    };

    return (
        <Row className='position-relative pt-5'>
                        <Col sm={12} md={12} lg={4} xl={4} xxl={4}>
                            <div className="h2 text-wit font-heavy">{t('connectors:teamleaderfocus:add:title')}</div>
                            <p className='pt-4 pb-5 text-wit'>
                                {t('connectors:teamleaderfocus:add:description')}
                            </p>
                        </Col>
                        <Col sm={12} md={12} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 5, offset: 1 }}>
                            <Form ref={formRef} validated={validated} noValidate className='d-flex flex-column'>

                                <span>{connectorContext.authorizationInfo?.authorizeUrl?.replace('{clientId}', connectorContext.authorizationInfo?.clientId)}</span>
                                
                                
                                <div className="d-flex justify-content-between align-items-center my-5">                                    
                                    <Button className='' variant="zwartblauw" onClick={() => authorize()}>
                                        {t('connectors:teamleaderfocus:add:authorize')} <i className="fas fa-chevron-right fa-md ms-2"></i>
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
    )
}

export default AuthorizeStep