import { CustomerConnector } from "@/connectors/model/customer-connector";
import { useNavigate } from "react-router-dom";
import ConnectorActiveIndicator from "../connector-active-indicator/connector-active-indicator";
import './customer-connector-card.scss';

type FunctionCardProps = {
    customerConnector: CustomerConnector;
}

function CustomerConnectorCard({ customerConnector: customerConnector }: FunctionCardProps) {

    const navigate = useNavigate();

    return (
        <div className="col-12 col-md-6 col-lg-4 mb-4" tabIndex={0} onClick={() => { navigate(`/profile/connectors/${customerConnector.enabled ? 'edit' : 'add'}/${customerConnector.name}`) }}>
            <div className="card card-case card-product border-0 h-100 bg-transparent">
                <div className="card-body">
                    <div className="product-image bg-gradient-turquoise p-4 p-lg-5">
                        {customerConnector.enabled &&
                        <div className="product-label product-favorite">
                            Aangeschafd

                            <img src="https://sparsedevelopment.nl/wp-content/themes/sparse/assets/img/sparse-icoon-diapositief.svg" alt="Sparse" className="img-fluid ml-2" width="28" height="28" />
                        </div> }
                        <div className="product-label product-new">New</div>
                        <div className="product-image-wrapper bg-wit">
                            <img src="https://images.pexels.com/photos/1432677/pexels-photo-1432677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="img-fluid" alt="" />
                        </div>
                        {/* <div className="card-active">
                            <ConnectorActiveIndicator enabled={customerConnector.enabled} />
                        </div> */}
                    </div>
                    <div className="product-info px-4 pt-4 px-lg-5">
                        <h3 className="h4 mb-0">{customerConnector.title}</h3>                        
                        <p className='card-description'>{customerConnector.description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerConnectorCard