import { Spinner } from 'react-bootstrap';
import './loading-component.scss';
import { Link } from 'react-router-dom';

type LoadingComponentProps = {
    message: string;
}

export function LoadingComponent({ message }: LoadingComponentProps) {

    return (
        <div className='loading-info'>
            <div className='loading-info__logo p-5'>
                <Link to='/'>
                    <img src={`/assets/img/sparse-logo-fullcolor.svg`} alt='' />
                </Link>
            </div>
            <div className="loading-info__content p-5">
                <Spinner animation="border" role="status" className='text-oranje'>
                    <span className="visually-hidden">{message}</span>
                </Spinner>
                <div className="h3 font-bold pt-4">{message}</div>
            </div>
        </div>
    );
}
