import FormCard from '@/components/cards/form-card';
import { FunctionContext } from '@/context/function-context';
import { useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmployerFunction } from '../model/employer-function';
import './function-details-card.scss';

function FunctionSecondaryEmploymentConditions() {

    const { t } = useTranslation();
    const context = useContext(FunctionContext);

    const updateTempChanges = (propertyName, newValue) => {

        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            employmentConditions: {
                ...context.tempChanges.employmentConditions,
                [propertyName]: newValue
            }
        };

        context.updateChanges(updatedFunction);
    };

    return (
        <FormCard title={t('functions:detail:secondaryEmploymentConditions:title')}>
            <Form.Group as={Row} className="">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:secondaryEmploymentConditions:labelCompanyCar')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('companyCar', true)}
                                className={`form-toggle-option ${context.tempChanges?.employmentConditions?.companyCar ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('companyCar', false)}
                                className={`form-toggle-option ${!context.tempChanges?.employmentConditions?.companyCar ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.employerFunction?.employmentConditions?.companyCar ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:secondaryEmploymentConditions:labelCompanyPhone')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('companyPhone', true)}
                                className={`form-toggle-option ${context.tempChanges?.employmentConditions?.companyPhone ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('companyPhone', false)}
                                className={`form-toggle-option ${!context.tempChanges?.employmentConditions?.companyPhone ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.employerFunction?.employmentConditions?.companyPhone ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:secondaryEmploymentConditions:labelExtraMonth')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('extraMonth', true)}
                                className={`form-toggle-option ${context.tempChanges?.employmentConditions?.extraMonth ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('extraMonth', false)}
                                className={`form-toggle-option ${!context.tempChanges?.employmentConditions?.extraMonth ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.employerFunction?.employmentConditions?.extraMonth ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:secondaryEmploymentConditions:labelProfitShare')}</Form.Label>
                    {context.editable ?
                        <div className='form-toggle'>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('profitShare', true)}
                                className={`form-toggle-option ${context.tempChanges?.employmentConditions?.profitShare ? 'selected' : ''}`}>
                                {t('general:yes')}
                            </span>
                            <span
                                role='button'
                                tabIndex={0}
                                onClick={() => updateTempChanges('profitShare', false)}
                                className={`form-toggle-option ${!context.tempChanges?.employmentConditions?.profitShare ? 'selected' : ''}`}>
                                {t('general:no')}
                            </span>
                        </div>
                        : <div>{context.employerFunction?.employmentConditions?.profitShare ? t('general:yes') : t('general:no')}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:secondaryEmploymentConditions:labelAdditionalInfo')}</Form.Label>
                    {context.editable ?
                        <Form.Control as="textarea" rows={3} placeholder="" value={context.tempChanges?.employmentConditions?.additionalInfoSecondary ?? ''} onChange={e => updateTempChanges('additionalInfoSecondary', e.target.value)} />
                        : <div>{context.employerFunction?.employmentConditions?.additionalInfoSecondary}</div>
                    }
                </Col>
            </Form.Group>
        </FormCard>
    );
}

export default FunctionSecondaryEmploymentConditions;