import FormCard from '@/components/cards/form-card';
import { FunctionContext } from '@/context/function-context';
import { useContractTypeTranslation } from '@/general/i18n/translation-helpers';
import { useContext } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ContractType } from '../model/contract-type';
import { EmployerFunction } from '../model/employer-function';
import './function-details-card.scss';

function FunctionPrimaryEmploymentConditions() {

    const context = useContext(FunctionContext);
    const { t } = useTranslation();
    const { translateContractType } = useContractTypeTranslation();
    const contractTypes = Object.values(ContractType).filter(value => !isNaN(Number(value)));

    const updateTempChanges = (propertyName, newValue) => {

        const updatedFunction: EmployerFunction = {
            ...context.tempChanges,
            employmentConditions: {
                ...context.tempChanges.employmentConditions,
                [propertyName]: newValue
            }
        };

        context.updateChanges(updatedFunction);
    };

    return (
        <FormCard title={t('functions:detail:primaryEmploymentConditions:title')}>
            <Form.Group as={Row}>
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:primaryEmploymentConditions:labelContractType')}</Form.Label>
                    {context.editable ?
                        <Form.Select
                            aria-label={t('functions:detail:primaryEmploymentConditions:placeholderContractType')}
                            value={context.tempChanges?.employmentConditions?.contractType || 0}
                            onChange={(e) => {
                                updateTempChanges('contractType', e.target.value)
                            }}
                        >
                            {contractTypes.map((level, index) => (
                                <option key={index} value={level}>
                                    {translateContractType(+level)}
                                </option>
                            ))}
                        </Form.Select>
                        : <div>{translateContractType(context.employerFunction?.employmentConditions?.contractType)}</div>
                    }
                </Col>
            </Form.Group>
            {
                context.tempChanges?.employmentConditions?.contractType == ContractType.Temporary &&
                <Form.Group as={Row} className="mt-3">
                    <Col>
                        <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:primaryEmploymentConditions:labelContractDurationInMonths')}</Form.Label>
                        {context.editable ?
                            <Form.Control type="number" min={0} placeholder="" value={context.tempChanges?.employmentConditions?.contractDurationInMonths || ''} onChange={e => updateTempChanges('contractDurationInMonths', e.target.value)} />
                            : <div>{context.employerFunction?.employmentConditions?.contractDurationInMonths}</div>
                        }
                    </Col>
                </Form.Group>
            }
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:primaryEmploymentConditions:labelProbationDurationInMonths')}</Form.Label>
                    {context.editable ?
                        <Form.Control type="number" min={0} placeholder="" value={context.tempChanges?.employmentConditions?.probationDurationInMonths || ''} onChange={e => updateTempChanges('probationDurationInMonths', e.target.value)} />
                        : <div>{context.employerFunction?.employmentConditions?.probationDurationInMonths}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:primaryEmploymentConditions:labelHoursPerWeek')}</Form.Label>
                    {context.editable ?
                        <Form.Control type="number" min={0} placeholder="" value={context.tempChanges?.employmentConditions?.hoursPerWeek || ''} onChange={e => updateTempChanges('hoursPerWeek', e.target.value)} />
                        : <div>{context.employerFunction?.employmentConditions?.hoursPerWeek}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>
                        {t('functions:detail:primaryEmploymentConditions:labelGrossSalaryPerMonth')}
                        <span className='info ms-2'>({t('functions:detail:primaryEmploymentConditions:labelGrossSalaryPerMonthInfo')})</span>
                    </Form.Label>
                    {context.editable ?
                        <Row className='gy-3'>
                            <Col sm={6} className='pb-0 border-0'>
                                <Form.Control type="number" min={0} placeholder={t('functions:detail:primaryEmploymentConditions:placeholderGrossSalaryPerMonthFrom')} value={context.tempChanges?.employmentConditions?.grossSalaryPerMonthFrom || ''} onChange={e => updateTempChanges('grossSalaryPerMonthFrom', e.target.value)} />
                            </Col>
                            <Col sm={6}>
                                <Form.Control type="number" min={0} placeholder={t('functions:detail:primaryEmploymentConditions:placeholderGrossSalaryPerMonthTo')} value={context.tempChanges?.employmentConditions?.grossSalaryPerMonthTo || ''} onChange={e => updateTempChanges('grossSalaryPerMonthTo', e.target.value)} />
                            </Col>
                        </Row>
                        :
                        <div>
                            {context.employerFunction?.employmentConditions?.grossSalaryPerMonthFrom &&
                                <>
                                    <span className='me-1'>{t('functions:detail:primaryEmploymentConditions:placeholderGrossSalaryPerMonthFrom')}</span>
                                    <span className='me-1'>{context.employerFunction?.employmentConditions?.grossSalaryPerMonthFrom}</span>
                                </>
                            }
                            {context.employerFunction?.employmentConditions?.grossSalaryPerMonthTo &&
                                <>
                                    <span className='me-1'>{t('functions:detail:primaryEmploymentConditions:placeholderGrossSalaryPerMonthTo')}</span>
                                    <span>{context.employerFunction?.employmentConditions?.grossSalaryPerMonthTo}</span>
                                </>
                            }
                        </div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>
                        {t('functions:detail:primaryEmploymentConditions:labelNumberOfHolidaysPerYear')}
                        <span className='info ms-2'>({t('functions:detail:primaryEmploymentConditions:labelNumberOfHolidaysPerYearInfo')})</span>
                    </Form.Label>
                    {context.editable ?
                        <Form.Control type="number" min={0} placeholder="" value={context.tempChanges?.employmentConditions?.numberOfHolidaysPerYear || ''} onChange={e => updateTempChanges('numberOfHolidaysPerYear', e.target.value)} />
                        : <div>{context.employerFunction?.employmentConditions?.numberOfHolidaysPerYear}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:primaryEmploymentConditions:labelHolidayAllowancePercentage')}</Form.Label>
                    {context.editable ?
                        <InputGroup className="mb-3">
                            <Form.Control type="number" min={0} placeholder="" value={context.tempChanges?.employmentConditions?.holidayAllowancePercentage || ''} onChange={e => updateTempChanges('holidayAllowancePercentage', e.target.value)} />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                        : <div>{context.employerFunction?.employmentConditions?.holidayAllowancePercentage}{context.employerFunction?.employmentConditions?.holidayAllowancePercentage && '%'}</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
                <Col>
                    <Form.Label className={`${!context.editable && 'no-edit'}`}>{t('functions:detail:primaryEmploymentConditions:labelAdditionalInfo')}</Form.Label>
                    {context.editable ?
                        <Form.Control as="textarea" rows={3} placeholder="" value={context.tempChanges?.employmentConditions?.additionalInfoPrimary ?? ''} onChange={e => updateTempChanges('additionalInfoPrimary', e.target.value)} />
                        : <div>{context.employerFunction?.employmentConditions?.additionalInfoPrimary}</div>
                    }
                </Col>
            </Form.Group>
        </FormCard>
    );
}

export default FunctionPrimaryEmploymentConditions;